import React, { useRef,useState } from 'react';
import { useSelector } from 'react-redux';
import SearchFiltersPanel from './SearchFiltersPanel';
import { Button, Badge, notification, Input, Space,message, Col,Row } from 'antd';
import { SearchOutlined,PlayCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NonSubIcon, SubIcon , NeverSubIcon} from '../common/CustomerIcons';
import { faFileExport, faPen } from '@fortawesome/free-solid-svg-icons';
import { CommonTable } from '../common';
import { CONST_PAGINATION_TEN, DATE_TIME_ONE_FORMAT } from '../../common/constants';
import {
  useFetchOgmSubsData, useResetOgmSubsData, useSaveSearchConditionData, useExportOgmSubsData, useUpdateSubstitutionsData,
  useDeleteManageSubs,
} from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
import moment from 'moment';
import _ from 'lodash';
import { CONST_OGM_SUBS_TABLE_PARENT_COLUMNS,CONST_OGM_SUBS_TABLE_EXPANDED_COLUMNS } from '../../common/ogmSubsConstants';
import OgmSubsOperateModal from './OgmSubsOperateModal';
import { CORP_USER_LIST } from '../../common/corpProfile';
import { CONST_SUB_RULE_TYPE, CONST_NONE_SUB_RULE_TYPE,CONST_NEVER_SUB_RULE_TYPE, CONST_DROP_OUT_RULE_TYPE } from '../../common/manageSubsConstants';
import { uniqueTerritoryList } from './getOriginalSelectedList';
import { formatTerrirotyData } from './formattedSearchCondition';
import { datadogRum } from '@datadog/browser-rum';
export default function OgmSubs() {
  const {
    ogmSubsData,
    searchConditionData,
    fetchOgmSubsDataPending,
    territoryData,
    userId,
    originalList,
  } = useSelector(state => ({
    ogmSubsData: state.manageSubs.ogmSubsData,
    searchConditionData: state.manageSubs.searchConditionData,
    fetchOgmSubsDataPending: state.manageSubs.fetchOgmSubsDataPending,
    territoryData: state.dashboard.territoryData,
    userId: state.home.auth.userId,
    originalList: state.manageSubs.originalList,
  }));
  const { fetchOgmSubsData } = useFetchOgmSubsData();
  const { exportOgmSubsData } = useExportOgmSubsData();
  const { resetOgmSubsData } = useResetOgmSubsData();
  const { saveSearchConditionData } = useSaveSearchConditionData();
  const [resetActivePagination, setResetActivePagination] = useState(false);
  const [isOgmSubsModalVisible, setOgmSubsModalVisible] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { updateSubstitutionsData } = useUpdateSubstitutionsData();
  const { deleteManageSubs } = useDeleteManageSubs();
  const [title, setModelTitle] = useState('');
  const renderParentElement = [
    { //render siteID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.siteDesc}</div>
          </>
        )
      }
    },
    { //render conceptID
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              <div className="col-title">{value}</div>
              <div className="col-desc">{record.nationalName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{record.customerNumber}</div>
            <div className="col-desc">{record.customerName}</div>
          </>
        )
      }
    },
    { //render originalItem
      extendRender: (value, label, record) => {
        let originalItemDetails = ``;
        if(value) {
          // originalItemDetails = `${record.originalItemDescription} <br/> ${record.originalUom} | ${record.originalPack}/${record.originalSize} | ${record.originalBrand} | ${record.originalStorageCode}`;
          originalItemDetails = `${record.originalUom} | ${record.originalPack}/${record.originalSize} | ${record.originalBrand} | ${record.originalStorageCode}`;
        }
        let category = record.itemCategory
        let categoryIcon = ''
        if(category) {
          category = category.trim()
          categoryIcon = category === 'C' ? <Badge className="sub-badge-item" color="#7AC943"/>
            : category === 'D' ? <Badge className="sub-badge-item" color="#FF931E"/>
            : category === 'B' ? <Badge className="sub-badge-item" color="#FFFF00"/>
            : category === 'A' ? <Badge className="sub-badge-item" color="#FF1022"/>
            : <span className="blank-badge" />
        } else {
          categoryIcon = <span className="blank-badge" />
        }
        return (
          <>
            <div className="col-title">
              {categoryIcon}
              {value}
            </div>
            <div className="col-desc handle-overflow detail-info">{record.originalItemDescription}</div>
            <div className="col-desc handle-overflow detail-info">{originalItemDetails}</div>
          </>
        )
      }
    },
    { //render subItem
      extendRender: (value, label, record) => {
        
        let subItemDetails = ``;
        let description=''

        if(value && value !== 'Multiple') {
          // subItemDetails = `${record.substituteItemDescription} | ${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
          subItemDetails = `${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
        }
        if(record.ruleType===CONST_NEVER_SUB_RULE_TYPE){
         description= <div className="col-desc handle-overflow">NEVER SUB</div>;
        }
        else{
          description=<div className="col-desc handle-overflow">{record.substituteItemDescription}</div>;
        }
        // if((value==='' && ( record.ruleType===CONST_SUB_RULE_TYPE || record.ruleType===CONST_NONE_SUB_RULE_TYPE) )|| record.ruleType==='dropout'){
        //COT-5289,dropout rule will returned as dropout rule type
        if(record.ruleType === CONST_DROP_OUT_RULE_TYPE){ 
          description= <div className="col-desc handle-overflow">DROPOUT</div>;
        }
        return (
          <>
            <Row>
              <Col span={3} className='flex align-items-center' style={{justifyContent:'center'}}>
                {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> : record.ruleType === CONST_NEVER_SUB_RULE_TYPE?<NeverSubIcon style={{ fontSize: '16px', color: '#db6f6f' }} />: record.ruleType === CONST_DROP_OUT_RULE_TYPE ?<PlayCircleFilled  rotate={90} style={{ fontSize: '16px', color: '#db6f6f'  }}/>:null}
              </Col>
              <Col span={21}>
                <div className="col-title">{value}</div>
                {description}
                <div className="col-desc  handle-overflow">{subItemDetails}</div>
              </Col>
            </Row>
          </>
        )
      }
    },
    {//render sub ratio
      extendRender: (value, label, record) => {
        if (record.ruleType === CONST_DROP_OUT_RULE_TYPE || record.ruleType === CONST_NONE_SUB_RULE_TYPE) {
          return (
            <>
              <div className="col-title">{record.reasonCode}</div>
              <div className="col-desc">{record.reasonDescription}</div>
            </>
          )
        } else {
          return value;
        }
      }                   
    },
    {},//endDate placeholder
    { //render modifiedBy
      extendRender: (value, label, record) => {
       
         const modifiedDate = record.modifiedTimestamp && moment(record.modifiedTimestamp).format(DATE_TIME_ONE_FORMAT);
        //const lastModifiedDate = value && moment(value).format(DATE_TIME_ONE_FORMAT);

        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${modifiedDate}`}</div>
          </>
        )
      }
    },
  ];
  const renderExpandedElement = [
    {}, //priority placeholder
    { //render subItem
      extendRender: (value, label, record) => {
        let subItemDetails = ``;
        if(value) {
          subItemDetails = `${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
        }
        return (
          <>
            <Row>
              <Col span={3} className='flex align-items-center' >
                {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> :record.ruleType === CONST_NEVER_SUB_RULE_TYPE?<NeverSubIcon style={{ fontSize: '16px', color: '#db6f6f' }} />: record.ruleType === CONST_DROP_OUT_RULE_TYPE ?<PlayCircleFilled  rotate={90} style={{ fontSize: '16px', color: '#db6f6f'  }}/>:null}
                {/* {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> : null} */}
              </Col>
              <Col span={21}>
                <div className="col-title">{value}</div>
                {record.ruleType === CONST_DROP_OUT_RULE_TYPE ? <div className="col-desc handle-overflow">DROPOUT</div> : <div className="col-desc">{record.substituteItemDescription}</div>}
                <div className="col-desc">{subItemDetails}</div>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      extendRender: (value, label, record) => {
        if (record.ruleType === CONST_DROP_OUT_RULE_TYPE || record.ruleType === CONST_NONE_SUB_RULE_TYPE) {
          return (
            <>
              <div className="col-title">{record.reasonCode}</div>
              <div className="col-desc">{record.reasonDescription}</div>
            </>
          )
        } else {
          return value;
        }
      }
    }, //reasonCode placeholder
    {}, //subRatio placeholder
    {}, //endDate placeholder
     // { //render createBy
    //   extendRender: (value, label, record) => {
    //     const createdDate = record.createTimestamp && moment(record.createTimestamp).format(DATE_TIME_ONE_FORMAT);
    //     return value && (
    //       <>
    //         <div className="col-title">{value}</div>
    //         <div className="col-desc">{`${record.createdById} ${createdDate}`}</div>
    //       </>
    //     )
    //   }
    { //rerender lastModified
      extendRender: (value, label, record) => {
        const lastModifiedDate = value && moment(value).format(DATE_TIME_ONE_FORMAT);
        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${lastModifiedDate}`}</div>
          </>
        )
      }
    }
  ]
  const inputRef = useRef();
  let searchForm = null;
  let clearFiltersRef = null;
  let confirmRef = null;
  const handleTerritoryParam = (values) => {
    let isCorpProfileUser = false
    if(originalList.length === 0) {
      const user = CORP_USER_LIST.filter(item => item.userId === userId);
      if (!_.isEmpty(user)) {
        isCorpProfileUser = true
      }
    }
    if((territoryData && ((territoryData.role && territoryData.role.toLowerCase() === 'corp') || _.isEmpty(territoryData.role))) || isCorpProfileUser) {
      let temp = values.territoryInfoList ? values.territoryInfoList.trim() : ''
      if(temp) {
        return [{"terrName": temp}]
      }else {
        return []
      }
    }else {
      return values.territoryInfoList !== undefined && values.territoryInfoList !== null ? uniqueTerritoryList(formatTerrirotyData(values, territoryData)) : null
    }
  }
  const handleOriginalItemSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        ruleType: formVals.ruleType !== undefined ? formVals.ruleType : 'all',
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const handleOriginalItemReset = (clearFilters, confirm, dataIndex) => {
    setSearchText('');
    clearFilters();
    confirm({
      closeDropdown: true,
    });
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        ruleType: formVals.ruleType !== undefined ? formVals.ruleType : 'all',
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const originalItemSearchReset = () => {
    setSearchText('');
    if(clearFiltersRef) {
      clearFiltersRef()
    }
    if(confirmRef) {
      confirmRef({
        closeDropdown: true,
      });
    }
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      clearFiltersRef = clearFilters;
      confirmRef = confirm;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={inputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleOriginalItemReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ fontSize: '16px', color: filtered ? 'blue' : undefined }} />,
    // onFilter: (value, record) =>{
    //   return record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : ''
    // },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
  });
  const searchFormCb = (form) => {
    searchForm = form;
  };
  CONST_OGM_SUBS_TABLE_PARENT_COLUMNS[2] = {...CONST_OGM_SUBS_TABLE_PARENT_COLUMNS[2], ...getColumnSearchProps('originalItem')};
  let parentColumns = renderTableColumns(CONST_OGM_SUBS_TABLE_PARENT_COLUMNS, null, renderParentElement);
  let expandedColumns = renderTableColumns(CONST_OGM_SUBS_TABLE_EXPANDED_COLUMNS, null, renderExpandedElement);
  parentColumns.push({
    title: 'Actions',
    dataIndex: '',
    width: 80,
    render: (value, record) => {
      return (
        <div className="actions-cell">
          <Button type="link" onClick={() => onEdit(record)}>
            <FontAwesomeIcon icon={faPen} color='#6b6b6c' style={{ height: "15px", width: "15px" }} />
          </Button>
        </div>
      );
    }
  });
  const rowExpandable = (record) => {
    return record.expandedItems && record.expandedItems.length > 1;
  }
  const onExport = () => {
    const timing = Date.now();
    const params = { ...searchConditionData }
    if (ogmSubsData.totals > 100000) {
      message.error(`The maximum number of rules to be exported exceeds the 100,000 record limit. Please include additional filter criteria to refine the number of records`);
      return;
    }
    if (searchText) {
      params.originalItem = searchText
    }
    exportOgmSubsData(params);
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Download subs`, timing);
    });
  }
  
  const onEdit = (record) => {
    onToggleModal(setOgmSubsModalVisible);
    setEditableData(record);
    if(record && record.ruleType === null && (record.expandedItems && record.expandedItems.length === 0)) {
      setModelTitle(`Add sub rule`);
    } else {
      setModelTitle('Edit sub rule');
    }
  }
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  }
  const onUpdateSubs = (args, onCloseModal,wasDropout,expandedLength) => {
      const timing = Date.now();
      updateSubstitutionsData(args).then(data => {
        if(wasDropout && args.expandedItems != null) {
          if(expandedLength === null && args.expandedItems.length === 0) {
            message.success(`Deleted Drop out rule Successfully.`);
          }else if(expandedLength === null && args.expandedItems.length > 0 && args.dropOutRuleEndDate === undefined) {
            message.success(`Deleted Drop out rule Successfully.`);
            message.success(`Updated Substitutions Successfully.`);
          }else if(expandedLength !== null && args.expandedItems.length >= 0 && args.dropOutRuleEndDate === undefined) {
            message.success(`Deleted Drop out rule Successfully.`);
            message.success(`Updated Substitutions Successfully.`);
          }else{
            message.success(`Updated Substitutions Successfully.`);
          }
        }else{
          message.success(`Updated Substitutions Successfully.`);
        }
        onCloseModal();
        setResetActivePagination(!resetActivePagination);
      }).catch(err => {
        if(wasDropout && args.expandedItems != null) {
          if(expandedLength === null && args.expandedItems.length === 0) {
            message.error(`Delete Drop out Sub rule Failed, please try again.`);
          }else{
            if(err.isInvalidShipTo) {
              message.error(err.message)
            }else {
              message.error(`Update Substitutions Failed, please try again.`);
            }
          }
        }else{
          if(err.isInvalidShipTo) {
            message.error(err.message)
          }else {
            message.error(`Update Substitutions Failed, please try again.`);
          }
        }
      });
      datadogRum.onReady(function(){
        datadogRum.addTiming(`Update Subs`,timing);
      });
    }
  const onDeleteNeverSubs = (args, onCloseModal) => {
    const timing = Date.now();
    let isDropout = false;
    if (editableData !== null && editableData.ruleType === 'dropout') {
      isDropout = true;
    }
    deleteManageSubs(args)
      .then(data => {
        if (isDropout) {
          message.success(`Deleted Drop out rule Successfully.`);
        }
        else {
          message.success(`Deleted Never Sub rule Successfully.`);
        }

        onCloseModal();
        setResetActivePagination(!resetActivePagination);
      })
      .catch(err => {
        if (isDropout) {
          message.error(`Delete Drop out Sub rule Failed, please try again.`);
        }
        else {
          message.error(`Delete Never Sub rule Failed, please try again.`);
        }


      });
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Delete Never Subs`, timing);
    });
  }
  const renderOgmSubsModal = (
    <OgmSubsOperateModal
    title={title}
    isModalVisible={isOgmSubsModalVisible}
    footer={false}
    width={1390}
    onToggleModal={() => {onToggleModal(setOgmSubsModalVisible); setEditableData(null);}}
    cancelText="Reset"
    okText="Save"
    onOk={onUpdateSubs}
    onDelete={onDeleteNeverSubs}
    initValueData={editableData}
    // loading={updateSubstitutionsDataPending || deleteManageSubsPending}
    idle={()=>{notification.warning({
      message: `Idle Time Surpassed 5 minutes`,
      placement:'top',
      duration:0,
    })}}
    
  />
    );
  
  return (
    <div className="manage-subs-ogm-subs">
      <div className="chart-title font-size-16-bold" style={{ font: "Myriad Bold" }}>Search for Items</div>
      <SearchFiltersPanel searchForm={searchFormCb} originalItemSearchReset={originalItemSearchReset}/>
      <div className="flex align-item-center justify-end" style={{ marginBottom: "20px" }}>
        <Button type="link" title="Export" disabled={ogmSubsData === null} onClick={onExport}>
          <FontAwesomeIcon icon={faFileExport} color={ogmSubsData ? "#6b6b6c" : "#a1a1a2"} style={{ height: "20px", width: "20px" }} />
          <p style={{ marginTop: "-5px", fontSize: "12px", marginLeft: "-3px", color: (ogmSubsData ? "#6b6b6c" : "#a1a1a2") }}>Export</p>
        </Button>
      </div>
      <CommonTable
        rowKey={record => `${record.site}-${record.nationalId}-${record.customerNumber}-${record.originalItem}-${record.originalUom}`}
        columns={parentColumns}
        tableClassName={ogmSubsData ? "subs-report-table" : ""}
        datasource={ogmSubsData}
        loadingData={fetchOgmSubsDataPending}
        expandedColumns={expandedColumns}
        expandedItemDataKey="expandedItems"
        rowExpandable={rowExpandable}
        paginationStyle={{ textAlign: "right", margin: '16px 0px' }}
        triggerConditions={searchConditionData}
        searchText={searchText}
        onFecthData={fetchOgmSubsData}
        onResetStore={resetOgmSubsData}
        scrollStyle={{ x: 'max-content', y: 300 }}
        paginationSetting={CONST_PAGINATION_TEN}
        resetActivePagination={resetActivePagination}
      />
       {isOgmSubsModalVisible && renderOgmSubsModal}
    </div>
  );
};

OgmSubs.propTypes = {};
OgmSubs.defaultProps = {};
