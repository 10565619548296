import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_BEGIN,
  DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_SUCCESS,
  DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_FAILURE,
  DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_DISMISS_ERROR,
} from './constants';

export function fetchTerritoryByDistrict(site,district) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/getTerritoryByDistrict?sites=${site}&districts=${district}`, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          const territoryByDistrict = res.data.map(item => item.territories).flat().map(item => {
            return {
              "terrId": item.id,
              "terrName": item.name,
              "maName": null,
              "opcoId": item.site,
              "type": "TERRITORY",
              "siteIdAndTerrName": `${item.site}-${item.name}`,
            }
          });
          dispatch({
            type: DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_SUCCESS,
            data: territoryByDistrict,
          });
          resolve(territoryByDistrict);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchTerritoryByDistrictError() {
  return {
    type: DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_DISMISS_ERROR,
  };
}

export function useFetchTerritoryByDistrict() {
  const dispatch = useDispatch();

  const { fetchTerritoryByDistrictPending, fetchTerritoryByDistrictError } = useSelector(
    state => ({
      fetchTerritoryByDistrictPending: state.dashboard.fetchTerritoryByDistrictPending,
      fetchTerritoryByDistrictError: state.dashboard.fetchTerritoryByDistrictError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchTerritoryByDistrict(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchTerritoryByDistrictError());
  }, [dispatch]);

  return {
    fetchTerritoryByDistrict: boundAction,
    fetchTerritoryByDistrictPending,
    fetchTerritoryByDistrictError,
    dismissFetchTerritoryByDistrictError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchTerritoryByDistrictPending: true,
        fetchTerritoryByDistrictError: null,
      };

    case DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_SUCCESS:
      // The request is success
      return {
        ...state,
        territoryByDistrictData: action.data,
        fetchTerritoryByDistrictPending: false,
        fetchTerritoryByDistrictError: null,
      };

    case DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTerritoryByDistrictPending: false,
        fetchTerritoryByDistrictError: action.data.error,
      };

    case DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchTerritoryByDistrictError: null,
      };

    default:
      return state;
  }
}
