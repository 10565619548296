import { ACCOUNT_TYPE, ACCOUNT_TYPES, SELECT_ALL_OPTION } from '../../common/constants';
import { sortFiltersListByKey } from '../../common/sorterFunc';
import _ from 'lodash';


const getRegionSiteList = (marketList, regionList, orgList) => {
  let list = {
    market: [],
    region: [],
    site: [],
  };
  orgList.map(marketItem => {
    //if role is Corp level, market/region/site are null
    //if role is Market level, region/site are null
    //if role is Region level, site is null
    //Others are Site level/ onChange event
    if (!marketList) {
      list.market.push(marketItem.market);
    }
    marketItem.regions.map(regionItem => {
      if (marketList) {
        if (marketList.indexOf(marketItem.market) > -1) {
          list.region.push(regionItem.region);
        }
      } else {
        list.region.push(regionItem.region);
      }
      if (regionList) {
        if (regionList.indexOf(regionItem.region) > -1) {
          regionItem.sites.map(siteItem => {
            return list.site.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        }
      } else {
        if (marketList && marketList.indexOf(marketItem.market) > -1) {
          regionItem.sites.map(siteItem => {
            return list.site.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        } else if (!marketList) {
          regionItem.sites.map(siteItem => {
            return list.site.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        }
      }
      return null;
    });
    return null;
  });
  list.region = sortFiltersListByKey(list.region);
  list.site = sortFiltersListByKey(list.site, 'site_desc');
  return list;
};

export const getInitAllDropdownList = (orgList, market, region, sites) => {
  let initOrgList = {
    market: [],
    region: [],
    site: [],
  };
  const list = getRegionSiteList(market, region, orgList);
  if (sites) {
    //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
    if(list.site){
      const newSites = sites.filter((item1) => list.site.some((item2) => item2.site_nbr === item1.site_nbr));
      initOrgList = {
        market: market,
        region: region,
        site: newSites,
      }
    }else{
      initOrgList = {
        market: market,
        region: region,
        site: sites,
      };
    }
  } else if (region) {
    initOrgList = {
      market: market,
      region: region,
      site: list.site,
    };
  } else if (market) {
    initOrgList = {
      market: market,
      region: list.region,
      site: list.site,
    };
  } else {
    initOrgList = {
      market: list.market,
      region: list.region,
      site: list.site,
    };
  }

  return initOrgList;
};

export const getTerritoryList = (siteIdList, territoryData) => {
  if (territoryData) {
    const territoryList = territoryData.filter(item => siteIdList.indexOf(item.opcoId) > -1);
    return territoryList;
  }
  return [];
};

export const uniqueTerritoryList = territoryList => {
  let newList = [];
  let tempObj = {};
 
  if (territoryList) {
    for (let value of territoryList) {
      if (!tempObj[value.terrName]) {
        tempObj[value.terrName] = 1;
        newList.push(value);
      }
    }
  }
  return newList;
};

export const getOrgSelectedList = (
  value,
  label,
  orgSelectedList,
  orgList,
  orgDropdownList,
  setOrgDropdownList,
  territoryData,
  setTerritoryList,
  territoryList,
  conceptData,
  userBindedData,
  setDistrictList,
  // territoryByDistrict,
) => {
  let selectedList = value;
  let currTerritoryList = selectedList;
  const lastedSelectOption = selectedList[selectedList.length - 1];
  let newList = {
    newOrgSelectedList: {},
  };
  const formattedLabel = label && label.toLowerCase();
  if (formattedLabel === 'market' || formattedLabel === 'corp' || formattedLabel === 'rcm') {
    let currSelectedList = getRegionSiteList(selectedList, null, orgList);
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      currSelectedList = getRegionSiteList(orgDropdownList.market, null, orgList);
      selectedList = orgDropdownList.market;
    }
    const currSiteIdList = currSelectedList.site.map(item => item.site_nbr);
    currTerritoryList = getTerritoryList(currSiteIdList, territoryData);
    newList.newOrgSelectedList = {
      market: selectedList,
      region: [],
      site: [],
      territory: [],
      district: [],
    };
    setOrgDropdownList({
      ...orgDropdownList,
      region: currSelectedList.region,
      site: currSelectedList.site,
    });
    setTerritoryList(uniqueTerritoryList(currTerritoryList));
  } else if (formattedLabel === 'region') {
    let currSelectedList = getRegionSiteList(orgSelectedList.market, selectedList, orgList);
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      currSelectedList = getRegionSiteList(orgSelectedList.market, null, orgList);
      selectedList = currSelectedList.region;
      if(userBindedData?.regions){
        selectedList = userBindedData?.regions;
      }
    }
    const currSiteIdList = currSelectedList.site.map(item => item.site_nbr);
    currTerritoryList = getTerritoryList(currSiteIdList, territoryData);
    newList.newOrgSelectedList = {
      market: orgSelectedList.market,
      region: selectedList,
      site: [],
      territory: [],
      district: [],
    };
    if(!_.isEmpty(userBindedData?.sites)){
      currSelectedList.site = currSelectedList.site.filter((item1) => userBindedData?.sites.some((item2) => item2.site_nbr === item1.site_nbr))
    }
    setOrgDropdownList({
      ...orgDropdownList,
      site: currSelectedList.site,
    });
    setTerritoryList(uniqueTerritoryList(currTerritoryList));
  } else if (formattedLabel === 'site' || formattedLabel === 'ma') {
    let allSiteList = null;
    //the latest option is all
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      allSiteList = getRegionSiteList(orgSelectedList.market, orgSelectedList.region, orgList);
      //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
      if(orgDropdownList.site){
        allSiteList.site = orgDropdownList.site;
      }
        selectedList = allSiteList.site.map(item => item.site_nbr);
    }
    currTerritoryList = getTerritoryList(selectedList, territoryData);
    newList.newOrgSelectedList = {
      market: orgSelectedList.market,
      region: orgSelectedList.region,
      site: selectedList,
      territory: formattedLabel === 'ma' ? territoryList.filter(item => item.type === 'TERRITORY').map(item => item.terrName) : [],
      district: formattedLabel === 'ma' ? territoryList.filter(item => item.type === 'DISTRICT').map(item => item.terrName) : []
    };
    if(formattedLabel === 'site'){
      //set district list base on selected site and set territory list []
      if(setDistrictList){
        setDistrictList(uniqueTerritoryList(territoryData && territoryData.filter(item => item.type === 'DISTRICT' && selectedList.indexOf(item.opcoId) > -1)) || []);
      }
      setTerritoryList(uniqueTerritoryList(territoryData && territoryData.filter(item => item.type === 'TERRITORY' && selectedList.indexOf(item.opcoId) > -1)) || [])
    }
  } else if (formattedLabel === 'territory') {
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = territoryList.map(item => item.terrName);
    }
    newList.newOrgSelectedList = {
      ...orgSelectedList,
      [label]: selectedList,
    };
  // } else if (formattedLabel === 'district') {
  //     if (lastedSelectOption === SELECT_ALL_OPTION) {
  //       console.log('selectedList', uniqueTerritoryList(territoryData.filter(item => item.type === 'DISTRICT')).map(item => item.terrName));
        
  //       selectedList = uniqueTerritoryList(territoryData.filter(item => item.type === 'DISTRICT')).map(item => item.terrName);
  //     }
  //     newList.newOrgSelectedList = {
  //       ...orgSelectedList,
  //       territory: [],
  //       [label]: selectedList,
  //     };
  //     console.log('territoryList', territoryList);
  //     //call api to set territory list based on site and district
  //     setTerritoryList(uniqueTerritoryList(territoryByDistrict));
  } else if(formattedLabel === 'conceptid') {
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = conceptData;
    }
    newList.newOrgSelectedList = {
      ...orgSelectedList,
      [label]: selectedList,
    };
    delete newList.newOrgSelectedList.nationalId
  }else if(formattedLabel === 'accounttype'){
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = ACCOUNT_TYPES;
    }
    newList.newOrgSelectedList = {
      ...orgSelectedList,
      [label]: selectedList,
    };
  }
  return newList;
};
export const getDistinctObjects = (array, key1, key2) => {
  return [...new Map(array.map(item => [`${item[key1]}_${item[key2]}`, item])).values()];
};
