import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIdleTimer } from 'react-idle-timer';
import {
  SLASH_DATE_FORMAT,
  DATE_TIME_ONE_FORMAT,
  DATE_TIME_TWO_FORMAT,
  AUTH_ERROR_MSG,
} from '../../common/constants';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  Modal,
  DatePicker,
  Spin,
  Select,
  message,
} from 'antd';
import { PlusOutlined, SearchOutlined, PlayCircleFilled} from '@ant-design/icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { transferFromFormToResult, convertStorageCode } from './formattedSubsData';
import {
  useFetchValidationItemData,
  useFetchAddedSubsData,
  useResetConceptData,
  useFetchRecommendedProducts,
  useResetRecommendedProducts,
  useResetSubstituteProducts,
  useValidateSubItem,
} from './redux/hooks';
import { transferFromResultToForm, getUom, checkDropOutRule, getDropOutRuleCreatedInfo, } from './formattedSubsData';
import { CustomLabel, SiteConceptCommonPanel, SubDetail } from './';
import { REASON_CODE, TOGGLE_NEVER_SUB_TO_SUB_WARNING_MESSAGE, TOGGLE_ONLY_DROP_OUT_TO_SUB_WARNING_MESSAGE, TOGGLE_SUB_TO_NEVER_SUB_WARNING_MESSAGE } from '../../common/manageSubsConstants';
import ManageOutsOperateModal from './ManageOutsOperateModal';
import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';

export default function OgmSubsOperateModal(props) {
  const {
    userName,
    userId,
    expandedItemEntity,
    substitutionsHeaderEntity,
    conceptData,
    fetchValidationItemDataPending,
    fetchAddedSubsDataPending,
    fetchValidationCustomerDataPending,
    validateSubItemPending,
  } = useSelector(state => ({
    userName: state.home.auth.user.userName,
    userId: state.home.auth.user.userId,
    expandedItemEntity: state.manageSubs.expandedItemEntity,
    substitutionsHeaderEntity: state.manageSubs.substitutionsHeaderEntity,
    conceptData: state.manageSubs.conceptData,
    fetchValidationItemDataPending: state.manageSubs.fetchValidationItemDataPending,
    fetchAddedSubsDataPending: state.manageSubs.fetchAddedSubsDataPending,
    fetchValidationCustomerDataPending: state.manageSubs.fetchValidationCustomerDataPending,
    validateSubItemPending: state.manageSubs.validateSubItemPending,
  }));
  const {
    title,
    isModalVisible,
    footer,
    width,
    siteData,
    initValueData,
    onToggleModal,
    cancelText,
    okText,
    onOk,
    loading,
    onDelete,
    idle,
    // onChangeTitle
  } = props;

  const onIdle=()=>{
    idle()
    onModalClose()
  }
  
  const formattedRecord = initValueData && transferFromResultToForm(initValueData);
  const { fetchValidationItemData } = useFetchValidationItemData();
  const { validateSubItem } = useValidateSubItem();
  const { fetchAddedSubsData } = useFetchAddedSubsData();
  const { resetConceptData } = useResetConceptData();
  const [neverSubFlag, setNeverSubFlag] = useState(formattedRecord && !formattedRecord.ruleType);
  const [selectConceptKey, setSelectConceptKey] = useState('');
  const { fetchRecommendedProducts } = useFetchRecommendedProducts();
  const { resetRecommendedProducts } = useResetRecommendedProducts();
  const { resetSubstituteProducts } = useResetSubstituteProducts();
  const [isExist, setIsExist] = useState(false)
  const [originalUom, setOriginalUom] = useState('')
  const [isSelectSubModalVisible, setSelectSubModalVisible] = useState(false);
  const [baseData, setBaseData] = useState(null);
  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState(false)
  const [form] = Form.useForm();
  const [reloadData,setReloadData] = useState(null);
  const [isExistDropOutRule, setIsExistDropOutRule] = useState(initValueData && checkDropOutRule(initValueData))
  const [wasDropOutRule, setWasDropOutRule] = useState(initValueData && checkDropOutRule(initValueData))
  const [expandedLength] = useState(initValueData!==undefined && initValueData.expandedItems!==undefined?initValueData.expandedItems:null)
  const [idleTime]= useState(title && title.toLowerCase() === 'edit sub rule'? 1000*60*5: 1000*60*60);
  const idleTimer = useIdleTimer({ onIdle,timeout: idleTime, })
  const initCreatedTimestampFields = {
    createdByName: userName,
    createdById: userId,
    createTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const initModifiedTimestampFields = {
    modifiedByName: userName,
    modifiedById: userId,
    modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const initCreatedValues = {
    ruleType: true,
    // expandedSubItems: [{ ...expandedItemEntity, ...initCreatedTimestampFields, subPriority: '1', subRatio: '1.00', }],
    expandedSubItems: [],
    expandedNoSubItems: [],
    ...initCreatedTimestampFields,
    ...initModifiedTimestampFields,
  };
  const dropOutRuleCreateInfo = {
    createTimestamp: null,
    createdById: null,
    createdByName: null,
  }
  if(initValueData) {
    getDropOutRuleCreatedInfo(initValueData, dropOutRuleCreateInfo)
  }
  const initialValues = formattedRecord || initCreatedValues;
  // const dropoutCheck=  title==='Edit sub rule'?(((initValueData.substituteItem==='' && ( initValueData.ruleType===CONST_SUB_RULE_TYPE || initValueData.ruleType===CONST_NONE_SUB_RULE_TYPE) )|| initValueData.ruleType==='dropout'))? true : false:null;
  //COT-5289
  // const dropoutCheck=  title==='Edit sub rule'?(initValueData && initValueData.ruleType==='dropout')? true : false:null;
  const dropoutCheck = null;
  const handleItemPressEnter = (e) => {
    e.target.blur()
  };
  const handleItemChange = (value, label, key, listFieldName) => {
    let objFormValues = form.getFieldsValue();
    let objCurr = {};

    switch (label) {
      case 'site':
        const siteObj = siteData.find(item => item.opcoNo === value);
        objCurr = {
          [label]: value,
          siteDesc: siteObj ? siteObj.opcoName : '',
          nationalId: '',
          nationalName: '',
          customerId: '',
          customerName: '',
        };
        resetConceptData()
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      case 'nationalId':
        if ((value === undefined || value.length < 4) && conceptData) {
          resetConceptData();
        }
        objCurr = {
          [label]: value,
          nationalName: '',
          customerId: '',
          customerName: '',
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      case 'originalItem':
        objCurr = {
          [label]: value,
          originalBrand: '',
          originalItemDescription: '',
          originalPack: '',
          originalSize: '',
          originalStorageCode: '',
          originalUom: '',
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      case 'selectedUom':
        objCurr = {
          [label]: value,
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        const { site, siteDesc, nationalId, nationalName, customerNumber, customerName, originalItem, } = objFormValues;
        const formData = formattedSubmitFormData({...substitutionsHeaderEntity, 'originalUom': value, site, siteDesc, nationalId, nationalName, customerNumber, customerName, originalItem, });
        fetchAddedSubsData(formData).then(data => {
          if (typeof data === 'object') {
            setIsExist(true)
            getDropOutRuleCreatedInfo(data, dropOutRuleCreateInfo)
            if(checkDropOutRule(data)) {
              setIsExistDropOutRule(true)
            } else {
              setIsExistDropOutRule(false)
            }
            //COT-4634,store reload data
            setReloadData(data);
            const reloadFormValues = transferFromResultToForm(data);
            reloadFormValues.selectedUom = reloadFormValues.originalUom
            reloadFormValues.originalUom = objFormValues.originalUom
            form.setFieldsValue(reloadFormValues);
            setNeverSubFlag(!reloadFormValues.ruleType);
            if(reloadFormValues.ruleType && (!reloadFormValues.expandedSubItems || reloadFormValues.expandedSubItems.length === 0) && (!reloadFormValues.expandedNoSubItems || reloadFormValues.expandedNoSubItems.length === 0)) {
              setSaveBtnDisabled(true)
            } else {
              setSaveBtnDisabled(false)
            }
          } else if(data === '') {
            setIsExist(false)
            setIsExistDropOutRule(false)
            dropOutRuleCreateInfo.createTimestamp = null
            dropOutRuleCreateInfo.createdById = null
            dropOutRuleCreateInfo.createdByName = null
            form.setFieldsValue({ ...objFormValues, ...initCreatedValues });
            const neverSubFlag = initCreatedValues.ruleType === false;
            setNeverSubFlag(neverSubFlag);
             const values={ ...objFormValues, ...initCreatedValues }
            if(values.ruleType && (!values.expandedSubItems || values.expandedSubItems.length === 0) && (!values.expandedNoSubItems || values.expandedNoSubItems.length === 0)) {
              setSaveBtnDisabled(true)
            } else {
              setSaveBtnDisabled(false)
            }
            //setSaveBtnDisabled(false)
          } else {
            message.error(`There is an error. Please refresh page or contact administrator.`);
          }
        });
        /*const { expandedSubItems, expandedNoSubItems } = objFormValues
        const errorList = []
        expandedSubItems.forEach((item, index) => {
          if(item.substituteUom) {
            if(item.substituteUom != "CS-EA" && item.substituteUom != value) {
              item.isInvalidUOM = true
              errorList.push({
                "name": ["expandedSubItems", index, "substituteItem"],
                "errors": ['UOM mismatch!'],
              })
            } else {
              item.isInvalidUOM = false
              errorList.push({
                "name": ["expandedSubItems", index, "substituteItem"],
                "errors": null,
              })
            }
          }
        })
        expandedNoSubItems.forEach((item, index) => {
          if(item.substituteUom) {
            if(item.substituteUom != "CS-EA" && item.substituteUom != value) {
              item.isInvalidUOM = true
              errorList.push({
                "name": ["expandedNoSubItems", index, "substituteItem"],
                "errors": ['UOM mismatch!'],
              })
            } else {
              item.isInvalidUOM = false
              errorList.push({
                "name": ["expandedNoSubItems", index, "substituteItem"],
                "errors": null,
              })
            }
          }
        })
        form.setFields(errorList)*/
        return;
      case 'subPriority':
      case 'subRatio': 
      // const val = parseFloat(value);
      // const reg = new RegExp('^(((?!0+(?:\.0+)?$)(([1-9]{1}\\d{0,2})|(0{1}))(\\.\\d{1,2}))|([0-9]{1}\\d{0,2}))?$', 'g');
      // if(!reg.test(val)) {
      //   message.warning('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
      //   return
      // }
      case 'endDate':
        //COT-5289,when change endDate for never sub and drop out rule,should store modifiedByName,modifiedById,modifiedTimestamp
        if(neverSubFlag || dropoutCheck){
          form.setFieldsValue({...objFormValues,...initModifiedTimestampFields});
        }else{
          objFormValues[listFieldName][key] = {
            ...objFormValues[listFieldName][key],
            ...initModifiedTimestampFields,
            [label]: value,
          };
          form.setFieldsValue(objFormValues);
        }
        return;
      case 'reasonCode':
        objFormValues[listFieldName][key] = {
          ...objFormValues[listFieldName][key],
          ...initModifiedTimestampFields,
          [label]: value,
        };
        //parse reasonDescription field and save to database as this field is not display 
        if(label === 'reasonCode'){
          objFormValues[listFieldName][key] = {
            ...objFormValues[listFieldName][key],
            'reasonDescription':parseReasonDescription(value)
          }
        }
        form.setFieldsValue(objFormValues);
        return;
      case 'substituteItem':
        objCurr = {
          [label]: value,
          substituteBrand: '',
          substituteItemDescription: '',
          substitutePack: '',
          substituteSize: '',
          substituteStorageCode: '',
          substituteUom: '',
        };
        objFormValues[listFieldName][key] = {
          ...objFormValues[listFieldName][key],
          ...objCurr,
          ...initModifiedTimestampFields,
          ...initCreatedTimestampFields,
        };
        form.setFieldsValue(objFormValues);
        return;
      default:
        return;
    }
  };
  const parseReasonDescription = (reasonCode)=>{
    return REASON_CODE.filter(item=>item.key === reasonCode)[0].label;
  }
  const validDuplicatedItem = (value, objFormValues) => {
    const allSubItemsData =
      objFormValues.expandedSubItems &&
      objFormValues.expandedSubItems.concat(objFormValues.expandedNoSubItems);
    if(allSubItemsData){
      const dupIndex = allSubItemsData && allSubItemsData.filter(
        item => item.substituteItem && item.substituteItem.trim().toString() === value.trim().toString(),//defect:COT-5561,trim extra space for original item and substituteItem
      );
      return dupIndex;
    }else{
      return -1;
    }
  }
  
  //moment.js bug? I am not sure...
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let date = currentDate.getDate();
  if(month < 10) month = '0' + month;
  if(date < 10) date = '0' + date;
  //Create moment by year, month and day, instead of directly moment()
  let tempMoment = moment(`${year}${month}${date}`, 'YYYYMMDD')
  const startDate = tempMoment.subtract(0, 'day')
  const disablePastDt = current => {
    return current.isBefore(startDate);
  };
  const funcValidOriginalItem = (rule, value, callback) => {
    const timing = Date.now();
    if (value && value.length) {
      const objFormValues = form.getFieldsValue();
      const dupIndex = validDuplicatedItem(value, objFormValues);
      if (!validateNumericRegExp(value)) { //Item number should be always 7 digits,it cannot be less or more
        callback('Invalid Original Item!');
      } else if (value.length !== 7) {
        callback('Original Item should be 7 digits!');
      } else if (dupIndex.length > 0) {
        callback('Duplicated Original Item!');
      } else {
        const itemValidformData = { opcoId: objFormValues.site, productId: value };
        fetchValidationItemData(itemValidformData).then(
          productInfo => {
            //COT-5366 show original item kosher
            // form.setFieldsValue({ ...objFormValues, originalItemKosher: productInfo.kosher })
            const currFields = {
              originalItem: value,
              originalBrand: productInfo.brandId,
              originalItemDescription: productInfo.name,
              originalPack: productInfo.pack,
              originalSize: productInfo.size,
              originalStorageCode: productInfo.storageCode,
              originalUom: getUom({shipSplitsOnly:productInfo.shipSplitOnly, minimumSplit:productInfo.minimumSplit}),
              selectedUom: '',
              originalItemKosher: productInfo.kosher,
            };
            if(currFields.originalUom !== 'CS-EA') {
              currFields.selectedUom = currFields.originalUom
            } else {
              currFields.selectedUom = objFormValues.selectedUom || 'CS'
            }
            setOriginalUom(currFields.originalUom)
            const { site, siteDesc, nationalId, nationalName, customerNumber, customerName, originalItem, } = objFormValues;
            const formData = formattedSubmitFormData({...substitutionsHeaderEntity, 'originalUom': currFields.selectedUom, site, siteDesc, nationalId, nationalName, customerNumber, customerName, originalItem, });
            fetchAddedSubsData(formData).then(data => {
              if (typeof data === 'object') {
                setIsExist(true)
                getDropOutRuleCreatedInfo(data, dropOutRuleCreateInfo)
                if(checkDropOutRule(data)) {
                  setIsExistDropOutRule(true)
                } else {
                  setIsExistDropOutRule(false)
                }
                //COT-4634,store reload data
                setReloadData(data);
                const reloadFormValues = transferFromResultToForm(data);
                reloadFormValues.selectedUom = reloadFormValues.originalUom
                reloadFormValues.originalUom = currFields.originalUom
                reloadFormValues.originalItemKosher = currFields.originalItemKosher
                form.setFieldsValue(reloadFormValues);
                // onChangeTitle(data);
                setNeverSubFlag(!reloadFormValues.ruleType);
                // COT-5289,when reload drop out rule data in add screen,should hidden sub/never sub toggle,and show effective date field
                // if (reloadFormValues.ruleType === CONST_DROP_OUT_RULE_TYPE) {
                //   setNeverSubFlag(true);
                //   setDropoutCheck(true);
                // } else {
                //   setNeverSubFlag(!reloadFormValues.ruleType);
                //   setDropoutCheck(false);
                // }
                 if(reloadFormValues.ruleType && (!reloadFormValues.expandedSubItems || reloadFormValues.expandedSubItems.length === 0) && (!reloadFormValues.expandedNoSubItems || reloadFormValues.expandedNoSubItems.length === 0)) {
                  setSaveBtnDisabled(true)
                } else {
                  setSaveBtnDisabled(false)
                }
                callback();
              } else if(data === '') {
                setIsExist(false)
                setIsExistDropOutRule(false)
                dropOutRuleCreateInfo.createTimestamp = null
                dropOutRuleCreateInfo.createdById = null
                dropOutRuleCreateInfo.createdByName = null
                form.setFieldsValue({ ...objFormValues, ...currFields, ...initCreatedValues });
                const values={ ...objFormValues, ...currFields, ...initCreatedValues }
                const neverSubFlag = initCreatedValues.ruleType === false;
                setNeverSubFlag(neverSubFlag);
                if(values.ruleType && (!values.expandedSubItems || values.expandedSubItems.length === 0) && (!values.expandedNoSubItems || values.expandedNoSubItems.length === 0)) {
                  setSaveBtnDisabled(true)
                } else {
                  setSaveBtnDisabled(false)
                }
                //setSaveBtnDisabled(false)
                callback();
              } else {
                message.error(`There is an error. Please refresh page or contact administrator.`);
                callback()
              }
            });
          },
          err => {
            if (err !== AUTH_ERROR_MSG) {
              callback('Invalid Original Item!');
            }
          },
        );
      }
    } else {
      callback();
    }
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Validate Original Item`,timing);
    });
  };
  const handleOriginalItemValidator = (rule, value, callback) => {
    form.validateFields(['site', 'nationalId']).then(() => {
        funcValidOriginalItem(rule, value, callback);
    }).catch(errorInfo => {
      //ANTD 4.x BUG ISSUE: IT WILL ALWAYS GOTO CATCH EVEN NO ERRORS
      if (errorInfo.errorFields.length > 0) {
        callback();
      } else {
        funcValidOriginalItem(rule, value, callback);
      }
    });
  };
  const handleSubItemValidator = (rule, value, callback, label, key = 0) => {
    const timing = Date.now();
    form.validateFields(['site']).then(() => {
      if (value && value.length) {
        const objFormValues = form.getFieldsValue();
        if(!objFormValues.originalUom) {
          let errorList = []
          errorList.push({
            "name": ["originalItem"],
            "errors": ['Invalid Original Item!'],
          })
          form.setFields(errorList)
          return
        }
        const dupIndex = validDuplicatedItem(value, objFormValues);
        if (dupIndex.length > 1 || (objFormValues.originalItem && value.toString() === objFormValues.originalItem.toString())) {
          callback('Duplicated Sub/Not-Suit-Sub Item!');
        } else if (!validateNumericRegExp(value)) { //Item number should be always 7 digits,it cannot be less or more
          callback('Invalid Sub/Not-Suit-Sub Item!');
        } else if (value.length !== 7) {
          callback('Sub Item should be 7 digits!');
        } else {
          validateSubItem({
            "customerNumber": validateNumericRegExp(objFormValues.nationalId) ? objFormValues.nationalId : '',
            "item": value,
            "nationalId": validateNumericRegExp(objFormValues.nationalId) ? '' : objFormValues.nationalId,
            "site": objFormValues.site,
          }).then(res => {
            if(res && res.result && res.result === "Success") {
              const { data } = res
              const uom = getUom({shipSplitsOnly:data.shipSplitOnly, minimumSplit:data.minimumSplit});
              const selectedUom = objFormValues.selectedUom || objFormValues.originalUom
              const isInvalidUOM = uom !== 'CS-EA' && uom !== selectedUom
              const currFields = {
                substituteItem: value,
                substituteBrand: data.brandId,
                substituteItemDescription: data.name,
                substitutePack: data.pack,
                substituteSize: data.size,
                substituteStorageCode: data.storageCode,
                substituteUom: uom,
                isInvalidUOM,
              };
              objFormValues[label][key] = {
                ...objFormValues[label][key],
                ...currFields,
                ...initModifiedTimestampFields,
              }
              form.setFieldsValue(objFormValues);
              if(isInvalidUOM) {
                callback('UOM mismatch!');
              } else {
                callback();
              }
            }else if(res && res.result && res.result === "Failed") {
              const { errorMessage } = res
              callback(errorMessage)
            }else {
              callback('Invalid Sub/Not-Suit-Sub Item!');
            }
          }, err => {
            if(err === AUTH_ERROR_MSG) {
              callback()
            }else {
              callback('Invalid Sub/Not-Suit-Sub Item!');
            }
          })
        }
      } else {
        callback();
      }
    }).catch(errorInfo => {
      callback();
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Validate Sub/Not-Suit-Sub Item`,timing);
    });
  };
  //To validate search text is only numeric or not
  const validateNumericRegExp = value => {
    const pattNumeric = new RegExp('^[0-9]*$', 'g');
    const isNumeric = pattNumeric.test(value);
    return isNumeric;
  };
  const onToggleSubNeverRuleType = checked => {
    const objForm = form.getFieldsValue();
    const { expandedSubItems, expandedNoSubItems, ruleType } = objForm;
    setIsExistDropOutRule(false)
    if (checked) {
      const objNewSubFields = {
        // expandedSubItems: [{ ...expandedItemEntity, ...initCreatedTimestampFields, subPriority: '1', subRatio: '1.00' },],
        expandedSubItems: [],
        expandedNoSubItems: [],
        ...initModifiedTimestampFields,
      };
      form.setFieldsValue({ ...objForm, ...objNewSubFields });
    } else {
      delete objForm.expandedSubItems;
      delete objForm.expandedNoSubItems;
      //COT-9085:UI - BOS2.0 -INC000004991046- When creating a drop out rule, SUS did not delete expired never sub rules-remove default endDate if switch other rule types to neversub and original sub is not never sub
      let endDate = null;
      if(title && title?.toLowerCase() === 'edit sub rule' && initValueData && initValueData?.ruleType === 'neversub' && !_.isEmpty(initValueData?.endDate)){
        endDate = moment(initValueData.endDate);
      }else if(title && title?.toLowerCase() === 'add sub rule' && reloadData && reloadData?.ruleType === 'neversub' && !_.isEmpty(reloadData?.endDate)){
        endDate = moment(reloadData.endDate);
      }
      form.setFieldsValue({ ...objForm, ...initModifiedTimestampFields, ...initCreatedTimestampFields,endDate:endDate });
    }
    if(ruleType && (!expandedSubItems || expandedSubItems.length === 0) && (!expandedNoSubItems || expandedNoSubItems.length === 0)) {
      setSaveBtnDisabled(true)
    } else {
      setSaveBtnDisabled(false)
    }
    setNeverSubFlag(!checked);
    //setSaveBtnDisabled(false)
    //defect:COT-4634,Warning message is not being displayed when a new sub/no-sub rule is created when a never sub rule is existing for the site id - concept/ship to - original item combination
    let newObjForm = form.getFieldsValue();
    if(!dropoutCheck && title && title.toLowerCase() === 'edit sub rule'){
      checkSwitchRuleType(initValueData,newObjForm,true);
    }else if(title && title.toLowerCase() === 'add sub rule'){
      checkSwitchRuleType(reloadData,newObjForm,true);
    }
  };
  const onToggleSubNonRuleType = (checked, key = 0) => {
    //sub/none-sub item row lowbound start from 0.
    const objForm = form.getFieldsValue();
    let objSubInst = objForm.expandedSubItems;
    let objNonSubInst = objForm.expandedNoSubItems;
    const ruleType = checked;
    if (objSubInst.length === 4 && ruleType) {
      //if sub item has 4 items, can't switch from no-sub to sub
      objNonSubInst[key].ruleType = !ruleType;
    } else if (ruleType) {
      //if change from no-sub to sub, remove no-sub item row, add it to sub item row
      const counter = `${objSubInst.length + 1}`;
      const objRemoved = objNonSubInst.splice(key, 1);
      const {
        substituteItem,
        substituteItemDescription,
        substituteBrand,
        substitutePack,
        substituteSize,
        substituteStorageCode,
        substituteUom,
        endDate,
      } = objRemoved[0];
      const objFormattoSubItem = {
        ruleType,
        substituteItem,
        substituteItemDescription,
        substituteBrand,
        substitutePack,
        substituteSize,
        substituteStorageCode,
        substituteUom,
        endDate,
      }; //common fields between sub and no-sub
      objSubInst.push({
        ...expandedItemEntity,
        ...objFormattoSubItem,
        subPriority: counter,
        subRatio: '1.00',
        ...initModifiedTimestampFields,
        ...initCreatedTimestampFields,
      });
    } else {
      //if change from sub to no-sub, remove sub item row, add it to no-sub item row
      let objRemoved = objSubInst.splice(key, 1);
      const {
        substituteItem,
        substituteItemDescription,
        substituteBrand,
        substitutePack,
        substituteSize,
        substituteStorageCode,
        substituteUom,
        endDate,
      } = objRemoved[0];
      const objFormattoNonSubItem = {
        ruleType,
        substituteItem,
        substituteItemDescription,
        substituteBrand,
        substitutePack,
        substituteSize,
        substituteStorageCode,
        substituteUom,
        endDate,
        reasonCode: 'R30',
        reasonDescription: 'CUSTOMER NOT SATISFIED',
      }; //common fields between sub and no-sub
      objNonSubInst.push({
        ...expandedItemEntity,
        ...objFormattoNonSubItem,
        ...initModifiedTimestampFields,
        ...initCreatedTimestampFields,
      });
    }
    //to update sub item priority value when switch toggle
    objSubInst.forEach((item, index) => {
      if(index >= key) {
        item.subPriority = `${index + 1}`;
        item.modifiedByName = userName;
        item.modifiedById = userId;
        item.modifiedTimestamp = moment().format(DATE_TIME_TWO_FORMAT);
      }
    });
    const newObjForm = {
      ...objForm,
      expandedSubItems: objSubInst,
      expandedNoSubItems: objNonSubInst,
    };
    form.setFieldsValue(newObjForm);
  };
  const handleOperatedSubItems = (add, ruleType, fields, errors) => {
    let priorityObj = { subPriority: null };
    let subRatio = null;
    let reasonCode = null
    let reasonDescription = null
    if (ruleType) {
      priorityObj = { subPriority: `${fields.length + 1}` };
      subRatio = '1.00';
    } else {
      reasonCode = 'R30';
      reasonDescription = 'CUSTOMER NOT SATISFIED'
    }
    setSaveBtnDisabled(false)
    add({ ...expandedItemEntity, ruleType, ...initCreatedTimestampFields, ...priorityObj, subRatio, reasonCode, reasonDescription, });
  };
  const formattedSubmitFormData = values => {
    const customerObj = {
      nationalId: validateNumericRegExp(values.nationalId) ? '' : values.nationalId,
      nationalName: validateNumericRegExp(values.nationalId) ? '' : values.nationalName,
      customerNumber: validateNumericRegExp(values.nationalId) ? values.nationalId : '',
      customerName: validateNumericRegExp(values.nationalId) ? values.nationalName : '',
      originalUom: values.selectedUom || values.originalUom,
      ruleType: initValueData?initValueData.ruleType:'',
    };
    const extraInfo = {
      isExistDropOutRule,
      modifiedByName: userName,
      modifiedById: userId,
      modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
      createdByName: dropOutRuleCreateInfo.createdByName,
      createdById: dropOutRuleCreateInfo.createdById,
      createTimestamp: dropOutRuleCreateInfo.createTimestamp,
    }
    const formData = transferFromFormToResult({ ...values, ...customerObj }, extraInfo);
   
    //defect:COT-5561,trim the extra space for originalItem
    formData.originalItem = formData.originalItem.trim();
    
    
    return formData;
  };
  const checkUom = (formData) => {
    let { selectedUom, expandedSubItems, expandedNoSubItems } = formData
    if(!selectedUom) {
      selectedUom = formData.originalUom
    }
    let subItemsIsInvalid = false
    let noSubItemsIsInvalid = false
    if(!expandedSubItems) {
      subItemsIsInvalid = false
    } else {
      subItemsIsInvalid = expandedSubItems.some(item => {
        return item.substituteUom != 'CS-EA' && item.substituteUom != selectedUom
      })
    }
    if(!expandedNoSubItems) {
      noSubItemsIsInvalid = false
    } else {
      noSubItemsIsInvalid = expandedNoSubItems.some(item => {
        return item.substituteUom != 'CS-EA' && item.substituteUom != selectedUom
      })
    }
    return !subItemsIsInvalid && !noSubItemsIsInvalid
  };
  const onFinish = () => {
    let formData = form.getFieldsValue();
    const { expandedSubItems, expandedNoSubItems } = formData
    if(expandedSubItems) {
      for(const val of expandedSubItems){
        if(val.subRatio === "") {
          val.subRatio='1.00';
        }
      }
    }
    
    let validator = true;
    const allFieldsList = form.getFieldsError();
    const allErrorList = allFieldsList.filter(item => {
      return item.errors.length > 0;  //item has error msg
    });
    form.validateFields(['nationalId']).catch(errorInfo => {
      if (errorInfo.errorFields.length > 0) {
        validator = false;
      }
    }).finally(() => {
      const errorList = []
      if(!formData.originalItem) {
        errorList.push({
          "name": ["originalItem"],
          "errors": ['Please input Original Item!'],
        })
      }
      expandedSubItems && expandedSubItems.forEach((item, index) => {
        if(item.substituteItem == null || item.substituteItem == undefined || item.substituteItem.trim() == '') {
          errorList.push({
            "name": ["expandedSubItems", index, "substituteItem"],
            "errors": ['Please input Sub Item!'],
          })
        }
      })
      expandedNoSubItems && expandedNoSubItems.forEach((item, index) => {
        if(item.substituteItem == null || item.substituteItem == undefined || item.substituteItem.trim() == '') {
          errorList.push({
            "name": ["expandedNoSubItems", index, "substituteItem"],
            "errors": ['Please input Not-Suit-Sub Item!'],
          })
        }
      })
      if(errorList.length) {
        form.setFields(errorList)
        return
      }

      if (validator && formData && allErrorList.length === 0) {
        if( !(dropoutCheck!==null && dropoutCheck) && !checkUom(formData)) {
          message.error('UOM mismatch, please check!')
        } else {
          formData = formattedSubmitFormData(formData);
          
          formData.callFrom = 'ogmSubs';
          //defect:COT-4634,Warning message is not being displayed when a new sub/no-sub rule is created when a never sub rule is existing for the site id - concept/ship to - original item combination
          if(!dropoutCheck && title && title.toLowerCase() === 'edit sub rule'){
            checkSwitchRuleType(initValueData,formData,false);
          }else if(title && title.toLowerCase() === 'add sub rule'){
            checkSwitchRuleType(reloadData,formData,false);
          }
          resetConceptData();
          //COT-9112:BOS 2.0 -UI- Audit trail for manage subs, add modifiedByName,modifiedById,modifiedTimestamp attribute for collecting event
          formData = { ...formData, modifiedByName: userName, modifiedById: userId, modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT)};
          onOk(formData, onToggleModal, wasDropOutRule, expandedLength);        }
      } else if(allErrorList.length > 0) {
        form.setFields(allErrorList);
      }
    });
  };
  //defect:COT-4634,Warning message is not being displayed when a new sub/no-sub rule is created when a never sub rule is existing for the site id - concept/ship to - original item combination
  const checkSwitchRuleType = (initValueData,formData,isToggle) => {
    if(isToggle){
      //when toggle sub/never sub
      if (initValueData && formData) {
        // when switch rule type
        if ((initValueData.ruleType === 'sub' || initValueData.ruleType === null || initValueData.ruleType === 'nosub') && !formData.ruleType) {
          //when switch sub to never sub
          message.warning(TOGGLE_SUB_TO_NEVER_SUB_WARNING_MESSAGE);
        } else if (initValueData.ruleType === 'neversub' && formData.ruleType) {
          //when switch never sub to never sub
          message.warning(TOGGLE_NEVER_SUB_TO_SUB_WARNING_MESSAGE);
        }else if(initValueData.ruleType === 'dropout' && !formData.ruleType){
          //defect:COT-5562,Warning message regarding existing dropout rule is not displayed when user tries to edit the dropout rule to never sub rule.
          message.warning(TOGGLE_ONLY_DROP_OUT_TO_SUB_WARNING_MESSAGE);
        }
      }
    }else{
      //on click save button
      if (initValueData && formData) {
        if (initValueData.ruleType === 'neversub' && initValueData.expandedItems === null
          && formData.ruleType === '' && formData.expandedItems !== null) {
          message.warning(TOGGLE_NEVER_SUB_TO_SUB_WARNING_MESSAGE);
        } else if ((initValueData.ruleType === 'sub' || initValueData.ruleType === null || initValueData.ruleType === 'nosub')
          && initValueData.expandedItems !== null
          && formData.ruleType === 'neversub' && formData.expandedItems === null) {
          message.warning(TOGGLE_SUB_TO_NEVER_SUB_WARNING_MESSAGE);
        }else if(initValueData.ruleType === 'dropout'
        && (formData.ruleType === 'neversub' && formData.expandedItems === null)){
          //defect:COT-5562,Warning message regarding existing dropout rule is not displayed when user tries to edit the dropout rule to never sub rule.
          message.warning(TOGGLE_ONLY_DROP_OUT_TO_SUB_WARNING_MESSAGE);
        }
      }
    }
  }
  //add by jennifer
  //defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc)
  const validateSubRtio=(rule, value, callback)=>{
    const reg = new RegExp('^(((?!0+(?:\.0+)?$)(([1-9]{1}\\d{0,2})|(0{1}))(\\.\\d{1,2}))|((?!0+$)[0-9]{1}\\d{0,2}))?$', 'g');
    const reg1 = new RegExp('^[^a-zA-Z]+$', 'g');
    const reg2=new RegExp('(^[0-9]+[\\.]{0,1}[0-9]*(?<!\\.)$)', 'g');
    if(value !== null && value !== "" && !reg1.test(value)) {
      callback('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
    }else if(value !== null && value !== "" && !reg.test(parseFloat(value))) {
      callback('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
    }else if(value !== null && value !== "" && !reg2.test(value)) {
      callback('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
    }
    callback();
  }
  const onReset = () => {
    //defect:COT-5235,Reset functionality is not working properly in EDIT sub rule pop up
    let editVals = initValueData && transferFromResultToForm(initValueData)
    getDropOutRuleCreatedInfo(initValueData, dropOutRuleCreateInfo)
    if(initValueData && checkDropOutRule(initValueData)) {
      setIsExistDropOutRule(true)
    } else {
      setIsExistDropOutRule(false)
    }
    const formVals = editVals || {
      ruleType: true,
      // expandedSubItems: [{ ...expandedItemEntity, ...initCreatedTimestampFields, subPriority: '1', subRatio: '1.00' }],
      expandedSubItems: [],
      expandedNoSubItems: [],
      ...initCreatedTimestampFields,
      ...initModifiedTimestampFields,
    }
    const neverSubFlag = formVals.ruleType === false;
    setNeverSubFlag(neverSubFlag);
    setSaveBtnDisabled(false)
    form.resetFields();
    form.setFieldsValue(formVals);
    resetConceptData();
  };
  const onDeleteNeverSub = () => {
    let formData = form.getFieldsValue();
    formData = formattedSubmitFormData(formData);
    //COT-9112:BOS 2.0 -UI- Audit trail for manage subs, add modifiedByName,modifiedById,modifiedTimestamp attribute for collecting event
    formData = { ...formData, modifiedByName: userName, modifiedById: userId, modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT) };
    resetConceptData();
    onDelete(formData, onToggleModal)
  };
  const onModalClose = () => {
    resetConceptData();
    onToggleModal();
  };
  const handleRemoveSubItem = (remove, name) => {
    let objFormValues = form.getFieldsValue();
    const { expandedSubItems, expandedNoSubItems, ruleType } = objFormValues;
    expandedSubItems.forEach((item, index) => {
      if (index + 1 > name) {
        item.subPriority = `${index}`;
        item.modifiedByName = userName;
        item.modifiedById = userId;
        item.modifiedTimestamp = moment().format(DATE_TIME_TWO_FORMAT);
      }
    });
    form.setFieldsValue(objFormValues);
    if(ruleType && (!expandedSubItems || expandedSubItems.length === 1) && (!expandedNoSubItems || expandedNoSubItems.length === 0)) {
      setSaveBtnDisabled(true)
    } else {
      setSaveBtnDisabled(false)
    }
    remove(name)
  };
  const handleRemoveItem = (remove, name) => {
    const formVal = form.getFieldsValue()
    const { expandedSubItems, expandedNoSubItems, ruleType } = formVal
    if(ruleType && (!expandedSubItems || expandedSubItems.length === 0) && (!expandedNoSubItems || expandedNoSubItems.length === 1)) {
      setSaveBtnDisabled(true)
    } else {
      setSaveBtnDisabled(false)
    }
    remove(name)
  };
  const handleMoveSubItems = useCallback((move, fromIndex, toIndex) => {
    //index value start from 0
    if (fromIndex !== undefined && toIndex !== undefined) {
      let objFormValues = form.getFieldsValue();
      move(fromIndex, toIndex);
      const draggableItem = objFormValues.expandedSubItems.splice(fromIndex, 1);
      objFormValues.expandedSubItems.splice(toIndex, 0, draggableItem[0]);
      objFormValues.expandedSubItems.forEach((item, index) => {
        item.subPriority = `${index + 1}`;
        if (index <= Math.max(fromIndex, toIndex) && index >= Math.min(fromIndex, toIndex)) {
          item.modifiedByName = userName;
          item.modifiedById = userId;
          item.modifiedTimestamp = moment().format(DATE_TIME_TWO_FORMAT);
        }
      });
      form.setFieldsValue(objFormValues);
    }
  }, []); //eslint-disable-line
  const renderDraggableSubsRow = useCallback((key, name, fields, move, remove, ...resetField) => {
    return (
      <DraggableSubsRow
        key={key}
        name={name}
        fields={fields}
        move={move}
        remove={remove}
        {...resetField}
      />
    );
  }, []); //eslint-disable-line
  const DraggableSubsRow = ({ name, fields, move, remove, ...resetField }) => {
    const ref = useRef();
    const [{ handlerId }, drop] = useDrop({
      accept: 'draggableSubItem',
      collect: monitor => ({
        handlerId: monitor.getHandlerId(),
      }),
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.name;
        const hoverIndex = name;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        handleMoveSubItems(move, dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.name = hoverIndex;
      },
    });
    const [{ isDragging }, drag] = useDrag({
      type: 'draggableSubItem',
      item: () => {
        return { name };
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (  
    
      <Row
        ref={ref}
        data-handler-id={handlerId}
        gutter={6}
        className={`sub-item-list-item draggle-item ${isDragging ? 'dragging' : ''}`}
        
      >
        <Col span={3} className="flex align-items-center">
          <div className="toggle-desc">Sub</div>
          <Form.Item {...resetField} name={[name, 'ruleType']} valuePropName="checked">
            <Switch
              className="subs-switch"
              size="small"
              defaultChecked
              onChange={checked => onToggleSubNonRuleType(checked, name)}
            />
          </Form.Item>
          <div className="toggle-desc">Not-Suit-Sub</div>
        </Col>
        <Col span={2}>
          <Form.Item {...resetField} name={[name, 'subPriority']}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            {...resetField}
            className="sub-item-position"
            name={[name, 'substituteItem']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validateTrigger: ['onBlur'],
                validator: (rule, value, callback) =>
                  handleSubItemValidator(rule, value, callback, 'expandedSubItems', name),
              },
              {
                validateTrigger: ['onChange', 'onBlur'],
                required: true,
                message: 'Please input Sub Item!',
              },
            ]}
            //defect:COT-5561,trim extra space for original item and substituteItem when onchange
            getValueFromEvent={e => {
              return e.target.value.replace(/\s/g,'');
            }}
          >
            <Input
              onChange={e =>handleItemChange(e.target.value.replace(/\s/g,''), 'substituteItem', name, 'expandedSubItems')}
              onPressEnter={e => handleItemPressEnter(e)}
            />
          </Form.Item>
          <SubDetail name={name} form={form} label='expandedSubItems' />
        </Col>
        <Col span={1}>
          <SearchOutlined onClick={() => handleSubSearch('expandedSubItems', name)} style={{marginTop: '10px'}} />
        </Col>
        <Col span={2}>
          <Form.Item
            {...resetField}
            name={[name, 'subRatio']}
            rules={[
              { required: false },
              //add by jennifer
              //defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc)
              // { pattern: new RegExp('^(((?!0+(?:\.0+)?$)(([1-9]{1}\\d{0,2})|(0{1}))(\\.\\d{1,2}))|([0-9]{1}\\d{0,2}))?$', 'g'),
              //  message: 'Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!'},
              {validator: (rule, value, callback) => validateSubRtio(rule, value, callback)}
            ]}
          >
            <Input
              onChange={e => handleItemChange(e.target.value, 'subRatio', name, 'expandedSubItems')}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item {...resetField} name={[name, 'endDate']}>
            <DatePicker
              disabledDate={disablePastDt}
              onChange={(date, dateString) =>
                handleItemChange(date, 'endDate', name, 'expandedSubItems')
              }
              format={SLASH_DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        {/* {title === 'Edit sub rule' && (
          <Col span={4} style={{display:'inline',marginTop: '-6px'}}>
            <Form.Item
              className="flex align-items-center createdByName font-size-14-bold"
              name={[name, 'createdByName']}
            >
              <CustomLabel />
            </Form.Item>
            <div className="flex align-items-center series-tips-timestamp-items">
              <Form.Item className="tips-content-item" name={[name, 'createdById']}>
                <CustomLabel />
              </Form.Item>
              <Form.Item className="tips-content-item" name={[name, 'createTimestamp']}>
                <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
              </Form.Item>
            </div>
          </Col>
        )} */}
        <Col span={4}>
          <Form.Item
            className="flex align-items-center createdByName font-size-14-bold"
            name={[name, 'modifiedByName']}
          >
            <CustomLabel />
          </Form.Item>
          <div className="flex align-items-center series-tips-timestamp-items">
            <Form.Item className="tips-content-item" name={[name, 'modifiedById']}>
              <CustomLabel />
            </Form.Item>
            <Form.Item className="sub-item-position" name={[name, 'modifiedTimestamp']}>
              <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
            </Form.Item>
          </div>
        </Col>
        <Col className="flex align-items-center">
          <Button onClick={() => handleRemoveSubItem(remove, name)} className={'no-border'}>
            <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "13px", width: "13px" ,paddingRight:'20px'}}/> 
          </Button>
        </Col>
      </Row>
      
    );
  };
  const onSelectSubToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  };
  const handleSubSearch = (label, key = 0) => {
    form.validateFields(['site']).then(() => {
      const formVal = form.getFieldsValue()
      if(!formVal.originalUom) {
        let errorList = []
        errorList.push({
          "name": ["originalItem"],
          "errors": ['Invalid Original Item!'],
        })
        form.setFields(errorList)
        return
      }
      const isNum = validateNumericRegExp(formVal.nationalId)
      //cot-5366 if in edit sub screen will call validate item for getting kosher.
      if(title === 'Edit sub rule'){
        const itemValidformData = { opcoId: formVal.site, productId: formVal.originalItem };
        const tempData = {
          "site": formVal.site,
          "siteDesc": formVal.siteDesc,
          "nationalId": isNum ? '' : formVal.nationalId,
          "nationalName": isNum ? '' : formVal.nationalName,
          "customerNumber": isNum ? formVal.nationalId : '',
          "customerName": isNum ? formVal.nationalName : '',
          "conceptId": formVal.nationalId,
          "conceptName": formVal.nationalName,
          "originalItem": formVal.originalItem,
          "originalItemDescription": formVal.originalItemDescription,
          "originalItemUom": formVal.originalUom,
          "originalPack": formVal.originalPack,
          "originalSize": formVal.originalSize,
          // "kosher": productInfo.kosher,
          "originalBrand": formVal.originalBrand,
          "originalStorageCode": formVal.originalStorageCode,
          "casePrice": '---',
          "caseQuantity": '---',
          "isFromOuts": false,
          "label": label,
          "keyIndex": key,
        }
        fetchValidationItemData(itemValidformData).then(productInfo => {
          tempData.kosher = productInfo.kosher;
        }).catch(err => {
          tempData.kosher = false;
        }).finally(() => {
          setBaseData(tempData);
          onSelectSubToggleModal(setSelectSubModalVisible);
        })
      }else{
        setBaseData({
          "site": formVal.site,
          "siteDesc": formVal.siteDesc,
          "nationalId": isNum ? '' : formVal.nationalId,
          "nationalName": isNum ? '' : formVal.nationalName,
          "customerNumber": isNum ? formVal.nationalId : '',
          "customerName": isNum ? formVal.nationalName : '',
          "conceptId": formVal.nationalId,
          "conceptName": formVal.nationalName,
          "originalItem": formVal.originalItem,
          "originalItemDescription": formVal.originalItemDescription,
          "originalItemUom": formVal.originalUom,
          "originalPack": formVal.originalPack,
          "originalSize": formVal.originalSize,
          "kosher": formVal.originalItemKosher,
          "originalBrand": formVal.originalBrand,
          "originalStorageCode": formVal.originalStorageCode,
          "casePrice": '---',
          "caseQuantity": '---',
          "isFromOuts": false,
          "label": label,
          "keyIndex": key,
        })
        onSelectSubToggleModal(setSelectSubModalVisible);
      }
      fetchRecommendedProducts({
        "searchBy": "Item Description",
        "searchInput": formVal.originalItemDescription,
        "site": [formVal.site],
        "originalItemUom": formVal.originalUom,
        "originalItem": formVal.originalItem,
        "nationalId": isNum ? '' : formVal.nationalId,
        "customerNumber": isNum ? formVal.nationalId : '',
        "isFromOuts": false,
      }).catch(err => {
        message.error(`Search Recommended Substitutions Failed, please try again.`);
      });
    }).catch(() => {
    })
  };
  const selectSub = (product, label, key, subRatio, endDate) => {
    let formVal = form.getFieldsValue()
    let currFields = {
      substituteItem: product.supc,
    };
    formVal[label][key] = {
      ...formVal[label][key],
      ...currFields,
      ...initModifiedTimestampFields,
    }
    form.setFieldsValue(formVal);

    const errorList = []
    formVal = form.getFieldsValue()
    const dupIndex = validDuplicatedItem(product.supc, formVal);
    if (dupIndex.length > 1 || (formVal.originalItem && product.supc.toString() === formVal.originalItem.toString())) {
      errorList.push({
        "name": [label, key, "substituteItem"],
        "errors": ['Duplicated Sub/Not-Suit-Sub Item!'],
      })
    } else {
      const uom = baseData.originalItemUom;
      currFields = {
        substituteItem: product.supc,
        substituteBrand: product.sysco_brand, //Melody TODO need brandID
        substituteItemDescription: product.item_description,
        substitutePack: product.pack,
        substituteSize: product.size,
        substituteStorageCode: convertStorageCode(product.site_details[0].storageCode),
        substituteUom: uom,
        subRatio: label === 'expandedNoSubItems' ? null : subRatio,
        endDate,
      };
      formVal[label][key] = {
        ...formVal[label][key],
        ...currFields,
        ...initModifiedTimestampFields,
      }
      form.setFieldsValue(formVal);
      errorList.push({
        "name": [label, key, "substituteItem"],
        "errors": null,
      })
    }
    form.setFields(errorList)
  };
  const renderSelectSubModal = (
    <ManageOutsOperateModal
      title="ADDSUB"
      isModalVisible={isSelectSubModalVisible}
      footer={false}
      width={1210}
      onToggleModal={() => {onSelectSubToggleModal(setSelectSubModalVisible); resetRecommendedProducts(); resetSubstituteProducts();}}
      okText="Sub"
      baseData={baseData}
      onSelectSub={selectSub}
      fromSubs={true}
      idle={()=>{}}
    />
  );
  return (
    <>
    <Modal className="manage-subs-ogm-subs-operate-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={footer || true}
      width={width || 520}
    >

      <Spin
        spinning={
          loading ||
          fetchAddedSubsDataPending ||
          fetchValidationItemDataPending ||
          fetchValidationCustomerDataPending ||
          validateSubItemPending
        }
      >
        
        <Form
          form={form}
          name="manage-subs-operate-form"
          className="operate-form"
          autoComplete="off"
          initialValues={initialValues}
        >
          {formattedRecord && ( //this header shows just for Edit screen
              <Row gutter={1} justify="space-between" className="sub-item-header">
                <Col span={3}>
                  <Form.Item className="form-label static-form-item-value" name="site" label="Site ID:">
                    <CustomLabel />
                  </Form.Item>
                  <Form.Item className="tips-content-item edit-site-tip" name="siteDesc">
                    <CustomLabel />
                  </Form.Item>
                </Col>
                <Col span={ neverSubFlag ? 6 : 5} >
                  <Form.Item
                    className="form-label static-form-item-value"
                    name="nationalId"
                    //revert defect:COT-4950
                    label='Group/Ship To'
                  >
                    <CustomLabel />
                  </Form.Item>
                  <Form.Item className="tips-content-item edit-concept-tip" name="nationalName">
                    <CustomLabel />
                  </Form.Item>
                </Col>
                <Col span={ neverSubFlag ? 4 : 4}>
                  <Form.Item
                    className="form-label static-form-item-value"
                    name="originalItem"
                    label='Original Item'
                  >
                    <CustomLabel />
                  </Form.Item>
                  <div className="flex align-items-center series-edit-tips-content-items">
                    <Form.Item className="tips-content-item" name="originalItemDescription">
                      <CustomLabel suffix=" " />
                    </Form.Item>
                  </div>
                  <div className="flex align-items-center series-edit-tips-content-items-1">
                    <Form.Item className="tips-content-item" name="originalUom">
                      <CustomLabel suffix=" | " />
                    </Form.Item>
                    <Form.Item className="tips-content-item" name="originalPack">
                      <CustomLabel suffix="/" />
                    </Form.Item>
                    <Form.Item className="tips-content-item" name="originalSize">
                      <CustomLabel suffix=" | " />
                    </Form.Item>
                    <Form.Item className="tips-content-item" name="originalBrand">
                      <CustomLabel suffix=" | " />
                    </Form.Item>
                    <Form.Item className="tips-content-item" name="originalStorageCode">
                      <CustomLabel />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={2} className='uom-dropdown'>
                  <Form.Item
                    className="static-form-item-value"
                    label="UOM"
                    name="originalUom"
                  >
                    <CustomLabel/>
                  </Form.Item>
                </Col>
                {(neverSubFlag || dropoutCheck)&& (
                  <Col span={4}>
                    <Form.Item
                      className="form-label static-form-item-value"
                      label="Effective End Date"
                      name="endDate"
                    >
                      <DatePicker
                        disabledDate={disablePastDt}
                        format={SLASH_DATE_FORMAT}
                        onChange = {(date,dateString)=>handleItemChange(date, 'endDate')}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!dropoutCheck &&
                  <Col span={3} className="flex sub-neversub-btn">
                    <div className="toggle-desc">Sub</div>
                    <Form.Item name="ruleType" valuePropName="checked">
                      <Switch
                        className="subs-switch"
                        size="small"
                        defaultChecked={formattedRecord.expandedItems ? true : false}
                        onChange={checked => onToggleSubNeverRuleType(checked)}
                      />
                    </Form.Item>
                    <div className="toggle-desc">Never-Sub</div>
                  </Col>
                }
              </Row>
            )}
          {(isExistDropOutRule && !neverSubFlag) && (
            <Row gutter={1} className="sub-item-header" style={{marginTop:"20px",marginLeft:"0px"}}>
              <Col className='flex align-items-center' style={{paddingRight:'10.5px', justifyContent:'center'}}>
              <PlayCircleFilled  rotate={90} style={{ fontSize: '16px', color: '#db6f6f'  }}/>
              </Col>
              <Col span={2} style={{paddingTop:'4px'}}><span style={{fontWeight:'600',color:'#194F72'}}>Drop Out</span></Col>
              <Col>
                <Form.Item name="dropOutRuleEndDate" label="Effective End Date">
                  <DatePicker
                    disabledDate={disablePastDt}
                    // onChange={(date, dateString) => handleItemChange(date, 'endDate', name, 'expandedNoSubItems')}
                    format={SLASH_DATE_FORMAT}
                  />
                </Form.Item>
                  {/* if drop out rule need pass reason code and reason description to web service  when update substitution*/}
                  <div style={{ display: 'none' }}>
                    <Form.Item name="reasonCode">
                    </Form.Item>
                    <Form.Item name="reasonDescription">
                    </Form.Item>
                  </div>
              </Col>
              <Col>
                <Button onClick={() => setIsExistDropOutRule(false)} className={'no-border'}>
                  <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "13px", width: "13px" }}/> 
                </Button>
              </Col>
            </Row>
          )}
          {(!neverSubFlag && !dropoutCheck)&& (
            <Row>
              <Col span={24}>
                <Row gutter={6} className="sub-item-list-header">
                  <Col span={3}></Col>
                  <Col span={2}>Priority</Col>
                  <Col span={6}>Sub Item</Col>
                  <Col span={2}>Sub Ratio</Col>
                  <Col span={3}>Effective end date</Col>
                  {/* {title === 'Edit sub rule' && <Col span={4}>Created By</Col>} */}
                  <Col span={4}>Last Modified</Col>
                  <Col style={{paddingRight:'20px'}}>Actions</Col>
                </Row>
                  <Form.List name="expandedSubItems">
                    {(fields, { add, move, remove }, { errors }) => (
                      <DndProvider backend={HTML5Backend}>
                      <>
                        {fields.map(({ key, name, ...resetField }) =>
                          renderDraggableSubsRow(key, name, fields, move, remove, resetField),
                        )}
                        {fields.length < 4 && ( //for sub item, it just can be added up to 4 row
                          <Row>
                            <Col span={1} offset={23}>
                              <Form.Item>
                                <Button
                                  className="subs-header-btn no-border"
                                  onClick={() => handleOperatedSubItems(add, true, fields, errors)}
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </>
                      </DndProvider>
                    )}
                  </Form.List>
              </Col>
            </Row>
          )}
          {(!neverSubFlag && !dropoutCheck) && (
            <Row>
              <Col span={24}>
                <Row gutter={6} className="sub-item-list-header">
                  <Col span={3}></Col>
                  <Col span={5}>Sub Item</Col>
                  <Col span={1}></Col>
                  <Col span={3}>Effective end date</Col>
                  <Col span={3}>Reason Code</Col>
                  {/* {title === 'Edit sub rule' && <Col span={5}>Created By</Col>} */}
                  <Col span={4}>Last Modified</Col>
                  <Col>Actions</Col>
                </Row>
                <Form.List name="expandedNoSubItems">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...resetField }) => (
                        <Row key={key} gutter={6} className="sub-item-list-item">
                          <Col span={3} className="flex align-items-center">
                            <div className="toggle-desc">Sub</div>
                            <Form.Item
                              {...resetField}
                              name={[name, 'ruleType']}
                              valuePropName="checked"
                            >
                              <Switch
                                className="subs-switch"
                                size="small"
                                onChange={checked => onToggleSubNonRuleType(checked, name)}
                              />
                            </Form.Item>
                            <div className="toggle-desc">Not-Suit-Sub</div>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...resetField}
                              className="sub-item-position"
                              name={[name, 'substituteItem']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  validateTrigger: ['onBlur'],
                                  validator: (rule, value, callback) => handleSubItemValidator(rule, value, callback, 'expandedNoSubItems', name,),
                                },
                                {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  required:(dropoutCheck!==null && dropoutCheck)? false : true,
                                  message: 'Please input Not-Suit-Sub Item!',
                                },
                              ]}
                              //defect:COT-5561,trim extra space for original item and substituteItem when onchange 
                              getValueFromEvent={e => {
                                return e.target.value.replace(/\s/g,'');
                              }}
                            >
                              <Input
                                onChange = {e => handleItemChange(e.target.value.replace(/\s/g,''), 'substituteItem', name, 'expandedNoSubItems',)}
                                onPressEnter = {e => handleItemPressEnter(e)}
                              />
                            </Form.Item>
                            <SubDetail name={name} form={form} label='expandedNoSubItems' />
                          </Col>
                          <Col span={1}>
                            <SearchOutlined onClick={() => handleSubSearch('expandedNoSubItems', name)} style={{marginTop: '10px'}} />
                          </Col>
                          <Col span={3}>
                            <Form.Item {...resetField} name={[name, 'endDate']}>
                              <DatePicker
                                disabledDate={disablePastDt}
                                onChange={(date, dateString) => handleItemChange(date, 'endDate', name, 'expandedNoSubItems')}
                                format={SLASH_DATE_FORMAT}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...resetField}
                              name={[name, 'reasonCode']}
                              rules={[{ 
                                required:(dropoutCheck!==null && dropoutCheck)? false : true,
                                message: 'Please input Reason Code!'
                               }]}
                            >
                              {/* <Input
                                onChange={e =>
                                  handleItemChange(
                                    e.target.value,
                                    'reasonCode',
                                    name,
                                    'expandedNoSubItems',
                                  )
                                }
                              /> */}
                              <Select
                                  onChange={value => handleItemChange(value, 'reasonCode',name,'expandedNoSubItems')}
                                >
                                  {REASON_CODE.map(item => {
                                    return (
                                      <Select.Option key={item.key} value={item.key}>
                                        {item.key}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                            </Form.Item>
                            <Form.Item hidden={true}
                              {...resetField}
                              name={[name, 'reasonDescription']}
                            ></Form.Item>
                          </Col>
                          {/* {title === 'Edit sub rule' && (
                            <Col span={5} style={{display:'inline',marginTop: '-6px'}}>
                              <Form.Item 
                                className="flex align-items-center createdByName font-size-14-bold"
                                name={[name, 'createdByName']}
                              >
                                <CustomLabel />
                              </Form.Item>
                              <div className="flex align-items-center series-tips-timestamp-items">
                                <Form.Item
                                  className="tips-content-item"
                                  name={[name, 'createdById']}
                                >
                                  <CustomLabel />
                                </Form.Item>
                                <Form.Item
                                  className="tips-content-item"
                                  name={[name, 'createTimestamp']}
                                >
                                  <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
                                </Form.Item>
                              </div>
                            </Col>
                          )} */}
                          <Col span={4}>
                            <Form.Item
                              className="createdByName font-size-14-bold"
                              name={[name, 'modifiedByName']}
                            >
                              <CustomLabel />
                            </Form.Item>
                            <div className="flex align-items-center series-tips-timestamp-items">
                              <Form.Item className="tips-content-item" name={[name, 'modifiedById']}>
                                <CustomLabel />
                              </Form.Item>
                              <Form.Item className="sub-item-position" name={[name, 'modifiedTimestamp']}>
                                <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col className='flex align-items-center'>
                            <Button onClick={() => handleRemoveItem(remove, name)} className={'no-border'}>
                              <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "13px", width: "13px" }}/> 
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col span={1} offset={23}>
                          <Form.Item>
                            <Button
                              className="subs-header-btn no-border"
                              onClick={() => handleOperatedSubItems(add, false, fields, errors)}
                              icon={<PlusOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          )}
          {/** these form item just store parent level timestamp value, will be hidden */}
          <Form.Item hidden name="createdByName">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="createdById">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="createTimestamp">
            <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
          </Form.Item>
          <Form.Item hidden name="modifiedByName">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="modifiedById">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="modifiedTimestamp">
            <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
          </Form.Item>
          {!footer && (
            <div className="subs-footer flex justify-end">
              {((title === "Edit sub rule" && (neverSubFlag || dropoutCheck)) || (title === "Add sub rule" && isExist && (neverSubFlag || dropoutCheck))) &&
                <Button onClick={onDeleteNeverSub} className="default-btn">
                  Delete
                </Button>
              }
              <Button id="cancelbutton" className="default-btn" onClick={onReset} style={{marginLeft:"2px"}}>
                {cancelText}
              </Button>
              <Button id="okbutton" onClick={onFinish} disabled={title === "Add sub rule" && isSaveBtnDisabled && !isExistDropOutRule} type="primary" className='primary-btn' style={{marginLeft:"2px"}}>
                {okText}
              </Button>
            </div>
          )}
        </Form>
      </Spin>
      {isSelectSubModalVisible && renderSelectSubModal}
    </Modal>
    </>
  );
};
