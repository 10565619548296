import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  DASHBOARD_RESET_TERRITORY_BY_DISTRICT_DATA,
} from './constants';

export function resetTerritoryByDistrictData() {
  return {
    type: DASHBOARD_RESET_TERRITORY_BY_DISTRICT_DATA,
  };
}

export function useResetTerritoryByDistrictData() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetTerritoryByDistrictData(...params)), [dispatch]);
  return { resetTerritoryByDistrictData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_RESET_TERRITORY_BY_DISTRICT_DATA:
      return {
        ...state,
        territoryByDistrictData: [],
      };

    default:
      return state;
  }
}
