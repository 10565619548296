import moment from 'moment';
import { DASH_DATE_FORMAT } from '../../common/constants';
import { formatTerrirotyData } from '../dashboard/formatTerrirotyData';

export const formatFormdata = (values, territoryData) => {
  if (values) {
    const formdata = {
      deliveryDate: moment(values.deliveryDate).format(DASH_DATE_FORMAT),
      sites: values.site,
      territories: formatTerrirotyData(values, territoryData),
      conceptIds: values.conceptId !== undefined ? values.conceptId : [],
      nationalIds: values.nationalId !== undefined
      ? Array.isArray(values.nationalId)
        ? values.nationalId : typeof values.nationalId === 'string' ? values.nationalId.split() : [] : [],
    };

    return formdata;
  }
  return {};
};
export const convertToFooterColumns = (columnsProp)=>{
  if(columnsProp){
    let footerColumnsProp = JSON.parse(JSON.stringify(columnsProp));
    footerColumnsProp = footerColumnsProp.map(item=>{
     if(item['dataIndex'] === 'totalSales' || item['dataIndex'] === 'orderTotalSales' 
     || item['dataIndex'] === 'averageSell'
      // || item['dataIndex'] === 'offDaySales'
      ){
       item['render'] = (value) => value && `$${value.toLocaleString()}`;
     }
     if(item['dataIndex'] === 'salesPercentage' || item['dataIndex'] === 'gpPercentages'){
       item['render'] = (value) => value && `${value.toLocaleString()}%`;
     }
     if(item['toggleModal']){
       delete item['toggleModal'];
     }
    //  if(item['className']){
    //    delete item['className'];
    //  }
    //  if(item['renderBadge']){
    //    delete item['renderBadge'];
    //  }
    //  if(item['sorter']){
    //   delete item['sorter'];
    // }
    return item;
   });
    //insert total label
    footerColumnsProp[0] = {...footerColumnsProp[0],dataIndex: "totalIndex",className:"all-column",
    render: (text,record,index)=>{
      if(record.totalIndex){
        return 'ALL';
      }
      // else{
      //   return `${index+1}`
      // }
    }};
    return footerColumnsProp;
  }
  return columnsProp;
}