import axios from '../../common/redux/interceptor';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_FETCH_TERRITORY_DATA_BEGIN,
  DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS,
  DASHBOARD_FETCH_TERRITORY_DATA_FAILURE,
  DASHBOARD_FETCH_TERRITORY_DATA_DISMISS_ERROR,
  ERROR_CONTACT_ADMIN
} from './constants';
import { updateI18nLanguage } from '../../../i18n/i18n';

export function fetchTerritoryData(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_TERRITORY_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      if(args.isSpecial) {
        const languageLocaleKey  = getState().dashboard?.territoryData?.languageLocaleKey;
        let res = {data:{}}
        //all users as Corp users
        if(args.isSpecial) {
          res.data.role = 'Corp'
          res.data.conceptIds = null
          res.data.market = null
          res.data.nationalIds = null
          res.data.region = null
          res.data.sites = null
          res.data.territories = null
          res.data.accessible = true
          res.data.languageLocaleKey = languageLocaleKey
        }
        const data = {
          territoryData: res.data,
        };
        dispatch({
          type: DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS,
          data,
        });
        resolve(res.data);
      }else {
        const cognitoIdToken = getState().home.auth.cognitoIdToken;
        const token = `Bearer ${cognitoIdToken}`;
        const doRequest = axios.get(`${API_REQUEST_URL}/fetchTerritories?syscoId=${args}`, {
          headers: {
            Authorization: token,
          },
        });
        doRequest.then(res => {
          //If role === 'Corp' && sellerId === 'CABL', this is Canada Corp User
          let isCanadaCorpUser = false;
          let isCanadaUser = false;
          //if Canada user,not show order trail menu and manage subs menu
          if(res.data && res.data.sellerId === 'CABL'){
            isCanadaUser = true;
          }
          //if canada corp,disabled Natl ID，Concept ID
          if(res.data && res.data.role === 'Corp' && res.data.sellerId === 'CABL'){
            isCanadaCorpUser = true;
          }
          //if site and ma user, will not show region level report page
          let isSiteUser = false;
          if(res.data && (res.data.role === 'Site' || res.data.role === 'MA')){
            isSiteUser = true;
          }
          let isRcmUser = false;
          if(res.data && res.data.role === 'RCM'){
            isRcmUser = true;
            res.data.territories = null;
          }
          const data = {
            territoryData: res.data,
            isCanadaCorpUser: isCanadaCorpUser,
            isCanadaUser: isCanadaUser,
            isSiteUser: isSiteUser,
            isRcmUser: isRcmUser,
          };
          dispatch({
            type: DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS,
            data,
          });
          resolve(res.data);
        }, err => {
          const errorMsg = err && err.response ? err.response.data.message : ERROR_CONTACT_ADMIN;
          dispatch({
            type: DASHBOARD_FETCH_TERRITORY_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        });
      }
    });

    return promise;
  };
}

export function dismissFetchTerritoryDataError() {
  return {
    type: DASHBOARD_FETCH_TERRITORY_DATA_DISMISS_ERROR,
  };
}

export function useFetchTerritoryData() {
  const dispatch = useDispatch();

  const { territoryData, fetchTerritoryDataPending, fetchTerritoryDataError } = useSelector(
    state => ({
      territoryData: state.dashboard.territoryData,
      fetchTerritoryDataPending: state.dashboard.fetchTerritoryDataPending,
      fetchTerritoryDataError: state.dashboard.fetchTerritoryDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTerritoryData(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchTerritoryDataError());
  }, [dispatch]);

  return {
    territoryData,
    fetchTerritoryData: boundAction,
    fetchTerritoryDataPending,
    fetchTerritoryDataError,
    dismissFetchTerritoryDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_TERRITORY_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchTerritoryDataPending: true,
        fetchTerritoryDataError: null,
      };

    case DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        territoryData: action.data.territoryData,
        isCanadaCorpUser: action.data.isCanadaCorpUser,
        isCanadaUser: action.data.isCanadaUser,
        isSiteUser: action.data.isSiteUser,
        isRcmUser: action.data.isRcmUser,
        fetchTerritoryDataPending: false,
        fetchTerritoryDataError: null,
      };

    case DASHBOARD_FETCH_TERRITORY_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTerritoryDataPending: false,
        fetchTerritoryDataError: action.data.error,
      };

    case DASHBOARD_FETCH_TERRITORY_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchTerritoryDataError: null,
      };

    default:
      return state;
  }
}
