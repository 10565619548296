// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  orgList: [],
  orgConstData: {
    market: [],
    region: [],
    site: [],
  },
  fetchOrgInfoPending: false,
  fetchOrgInfoError: null,
  territoryData: null,
  fetchTerritoryDataPending: false,
  fetchTerritoryDataError: null,
  salesSummaryData: {
    totalAllocatedCases: null,
    totalAllocatedEaches: null,
    totalCases: null,
    totalEaches: null,
    totalExceptions: null,
    totalGPPercentage: null,
    totalLines: null,
    totalOrders: null,
    totalPickPieces: null,
    totalPickups: null,
    totalSales: null,
    exactExceptions: null,
    subsExceptions: null,
  },
  detailsCondition: null,
  dashboardConditionData: null,
  initTerritoryFiltersData: null,
  orderStatusSummaryData: {
    cancelled: null,
    creditHold: null,
    labelled: null,
    lockedOrErrored: null,
    merchHold: null,
    open: null,
    pendingApproval: null,
    routing: null,
    shippedOrInvoiced: null,
  },
  orderTrendSummaryData: [],
  salesTrendSummaryData: [],
  orderByTypeSummaryData: {
    dropShip: null,
    hotShot: null,
    regular: null,
    willCall: null,
    unconfirmed: null,
  },
  fetchSummaryDataPending: false,
  fetchSummaryDataError: null,
  nationalData: null,
  fetchNationalAccountDataPending: false,
  fetchNationalAccountDataError: null,
  conceptData: null,
  fetchConceptIdsDataPending: false,
  fetchConceptIdsDataError: null,
  orderReportData: null,
  fetchOrderReportDataPending: false,
  fetchOrderReportDataError: null,
  exportReportDataPending: false,
  exportReportDataError: null,
  isCanadaCorpUser: false,
  isCanadaUser: false,
  notificationStatus: false,
  fetchDefaultPasswordPending: false,
  fetchDefaultPasswordError: null,
  isSiteUser: false,
  dropDownList: null,
  isRcmUser: false,
  fetchTerritoryByDistrictPending: false,
  fetchTerritoryByDistrictError: null,
  territoryByDistrictData: [],
};

export default initialState;
