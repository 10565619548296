import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  MANAGE_SUBS_RESET_OGM_SUBS_DATA,
} from './constants';

export function resetOgmSubsData() {
  return {
    type: MANAGE_SUBS_RESET_OGM_SUBS_DATA,
  };
}

export function useResetOgmSubsData() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetOgmSubsData(...params)), [dispatch]);
  return { resetOgmSubsData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_OGM_SUBS_DATA:
      return {
        ...state,
        ogmSubsData: null,
      };

    default:
      return state;
  }
}
