import moment from 'moment';
import { SLASH_DATE_FORMAT } from './constants';

export const CONST_ALL_RULE_OPTIONS = [
  { label: 'Sub - Rule', key: 'sub' },
  { label: 'Not Suit Sub - Rule', key: 'nosub' },
  { label: 'Never Sub - Rule', key: 'neversub' },//defect:COT-4681,Unable to filter Never-Sub rules separate from All Rules in (Sub Repository) search sub drop down.
  { label: 'Drop Out - Rule', key: 'dropout' }, //change:add drop out rule type in search panel
  { label: 'All Rules', key: 'all' },
];

export const CONST_CATEGORY_OPTIONS = [
  { label: 'Red', key: 'A' },
  { label: 'Yellow', key: 'B' },
  { label: 'Green', key: 'C' },
  { label: 'Orange', key: 'D' },
];

export const CONST_MANAGED_BY_OPTIONS = [
  { label: 'Local', key: 'LOCAL' },
  { label: 'Central', key: 'CCC' },
  { label: 'CST', key: 'CST' },
];

export const REASON_CODE = [
  { label: 'OUTS AND SUBS REJECTED SUB', key: 'REJ' },
  { label: 'CUSTOMER NOT SATISFIED', key: 'R30' },
];
export const CONST_SUB_RULE_TYPE = 'sub';
export const CONST_NONE_SUB_RULE_TYPE = 'nosub';
export const CONST_NEVER_SUB_RULE_TYPE = 'neversub';
export const CONST_DROP_OUT_RULE_TYPE = 'dropout';

export const CONST_BOS_ROOT_PATH_NAME = '/manage-subs';
export const CONST_MAINTAIN_ACCOUNTS_PATH_NAME = '/manage-subs/maintain-accounts';
export const CONST_MANAGE_OUTS_PATH_NAME = '/manage-subs/manage-outs';
export const CONST_MANAGE_SUBS_PATH_NAME = '/manage-subs/sub-repositories';
export const CONST_ADMIN_CONSOLE_PATH_NAME = '/manage-subs/admin-console';
export const CONST_CUSTOMER_GROUP_PATH_NAME = '/manage-subs/manage-groups';
export const CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME = '/manage-subs/manage-accounts-audit-trail';
export const CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME = '/manage-subs/sub-repositories-audit-trail';
export const CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME = '/manage-subs/create-groups-audit-trail';
export const CONST_OGM_SUBS_PATH_NAME = '/manage-subs/ogm-subs';
export const CONST_SUBSTITUTIONS_TABLE_PARENT_COLUMNS = [
  {
    title: 'Site ID',
    dataIndex: 'site',
    width: 100,
    blankWidth: 100,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Group/Ship To',
    dataIndex: 'nationalId',
    width: 160,
    blankWidth: 160,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Original Item',
    dataIndex: 'originalItem',
    width: 200,
    blankWidth: 200,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Item',
    dataIndex: 'substituteItem',
    width: 200,
    blankWidth: 200,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Ratio/Reason Code',
    dataIndex: 'subRatio',
    width: 180,
    blankWidth: 180,
    render: false,
    extendRender: true,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    width: 110,
    blankWidth: 110,
    render: value => {
      const expiredDate = value && moment().diff(moment(value), 'days'); //expired date will return positive number
      if(value && expiredDate < 1) {
        return moment(value).format(SLASH_DATE_FORMAT);
      } else if(value) {
        return 'EXPIRED';
      }
    },
  },
  {
    title: 'Modified',
    dataIndex: 'modifiedByName',
    width: 190,
    blankWidth: 160,
    render: false,
    extendRender: true,
  },
  // {
  //   title: 'Actions',
  //   dataIndex: 'ruleType',
  //   width: 76,
  //   blankWidth: 76,
  //   render: false,
  //   extendRender: true,
  // },
];

export const CONST_SUBSTITUTIONS_UOM_OPTIONS =[
  { label: 'EA', key: 'EA' },
  { label: 'CS', key: 'CS' },
];

export const CONST_SUBSTITUTIONS_TABLE_EXPANDED_COLUMNS = [
  {
    title: 'Priority',
    dataIndex: 'subPriority',
    width: 80,
    blankWidth: 80,
    sorter: false,
    render: value => (value === null || value == '0') ? '-' : value,
  },
  {
    title: 'Sub Item',
    dataIndex: 'substituteItem',
    width: 200,
    blankWidth: 160,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Reason Code',
    dataIndex: 'reasonCode',
    width: 120,
    blankWidth: 120,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Ratio',
    dataIndex: 'subRatio',
    width: 90,
    blankWidth: 90,
    sorter: false,
    render: false,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    width: 100,
    blankWidth: 100,
    sorter: false,
    render: value => {
      const expiredDate = value && moment().diff(moment(value), 'days'); //expired date will return positive number
      if(value && expiredDate < 1) {
        return moment(value).format(SLASH_DATE_FORMAT);
      } else if(value) {
        return 'EXPIRED';
      }
    },
  },
  // {
  //   title: 'Created',
  //   dataIndex: 'createdByName',
  //   width: 160,
  //   blankWidth: 120,
  //   sorter: false,
  //   render: false,
  //   extendRender: true,
  // },
  {
    title: 'Last Modified',
    dataIndex: 'modifiedTimestamp',
    width: 130,
    blankWidth: 120,
    sorter: false,
    render: false,
    extendRender: true,
  },
];
export const SAVE_NEVER_SUB_TO_SUB_WARNING_MESSAGE ='The existing never sub rule will be deleted on saving!';
export const SAVE_SUB_TO_NEVER_SUB_WARNING_MESSAGE ='The existing sub rule will be deleted on saving!';
export const TOGGLE_NEVER_SUB_TO_SUB_WARNING_MESSAGE='Item has never sub rule for the given Con ID/Cust!';
export const TOGGLE_SUB_TO_NEVER_SUB_WARNING_MESSAGE='Item has sub rule for the given Con ID/Cust!';
//defect:COT-5562,Warning message regarding existing dropout rule is not displayed when user tries to edit the dropout rule to never sub rule.
export const TOGGLE_ONLY_DROP_OUT_TO_SUB_WARNING_MESSAGE='Item has drop out rule for the given Con ID/Cust!';