import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col, Form, Select, DatePicker, Button, Popover, Input, message } from 'antd';
import { SELECT_ALL_OPTION, DASH_DATE_FORMAT, ACCOUNT_TYPES } from '../../common/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import {
  useFetchOrgInfo,
  useFetchTerritoryData,
  useFetchSummaryData,
  useFetchNationalAccountData,
  useFetchConceptIdsData,
  useResetConceptIdsData,
  useResetSummaryData,
  useSaveDashboardConditionData,
  useResetDashboardConditionData,
  useSaveInitTerritoryFiltersData,
  useFetchDefaultPassword,
  useSaveDropDownList,
  useFetchTerritoryByDistrict,
  useResetTerritoryByDistrictData,
} from './redux/hooks';
import { useResetReportsData } from '../reports/redux/hooks';
import { getOrgSelectedList, uniqueTerritoryList,getTerritoryList, getDistinctObjects } from './getOrgSelectedList';
import { formatTerrirotyData } from './formatTerrirotyData';
import {ERROR_SELECT_TERRITORY} from './redux/constants'
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from "react-i18next";

export default function TerritoryFilters(props) {
  const { t } = useTranslation();
  const {isOpenPopFun,isOpenPop,isClosePopFun} = props
  const { fetchOrgInfo, orgList, orgConstData } = useFetchOrgInfo();
  const { territoryData, fetchTerritoryData } = useFetchTerritoryData();
  const { fetchSummaryData } = useFetchSummaryData();
  const { fetchNationalAccountData, nationalData } = useFetchNationalAccountData();
  const { fetchConceptIdsData, conceptData } = useFetchConceptIdsData();
  const { resetConceptIdsData } = useResetConceptIdsData();
  const { resetSummaryData } = useResetSummaryData();
  const { resetReportsData } = useResetReportsData();
  const { saveDashboardConditionData } = useSaveDashboardConditionData();
  const { resetDashboardConditionData } = useResetDashboardConditionData();
  const { initTerritoryFiltersData, saveInitTerritoryFiltersData } = useSaveInitTerritoryFiltersData();
  const [orgSelectedList, setOrgSelectedList] = useState(orgConstData);
  const [orgDropdownList, setOrgDropdownList] = useState(orgConstData);
  const [territoryList, setTerritoryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const { fetchTerritoryByDistrict } = useFetchTerritoryByDistrict();
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const { fetchDefaultPassword } = useFetchDefaultPassword();
  const { saveDropDownList } = useSaveDropDownList();
  const { resetTerritoryByDistrictData } = useResetTerritoryByDistrictData();
  const [conceptIdDropdown, setConceptIdDropdown] = useState([]);
  const { auth, dashboardConditionData,isCanadaCorpUser,dropDownList,isRcmUser,territoryByDistrictData, } = useSelector(state => ({
    auth: state.home.auth,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    isCanadaCorpUser: state.dashboard.isCanadaCorpUser,
    dropDownList: state.dashboard.dropDownList,
    isRcmUser: state.dashboard.isRcmUser,
    territoryByDistrictData: state.dashboard.territoryByDistrictData,
  }));
  
  useEffect(()=>{
    if(territoryData?.role === 'RCM' && !_.isEmpty(territoryData?.nationalIds)){
      form.setFieldsValue({nationalId: territoryData?.nationalIds});
      // fetchConceptIdsData(territoryData?.nationalIds);
      setConceptIdDropdown(territoryData?.conceptIds || []);
    }else{
      setConceptIdDropdown(conceptData || []);
    }
  },[territoryData,conceptData]);// eslint-disable-line
  //Test Code Only for dev and qa
  const [visible, setVisible] = useState(false);
  const [testUserId, setTestUserId] = useState(auth.user.userId);
  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newOpen) => {
    setVisible(newOpen);
  };
  const onClear = () => {
    hide();
  };
  const onOk = (values) => {
    resetTerritoryByDistrictData();
    form.resetFields();
    resetConceptIdsData();
    saveDropDownList(null);
    hide();
    fetchDefaultPassword().then(data=>{
      if(values.password === data){
        let userId = testUserId ? testUserId : auth.user.userId
        //nbur3044 region; //abry1817 ma //rkea4241 site //ecui8760 corp
        getTerritoryData(userId)
      }else{
        message.error('You do not have permission!')
      }
    })
    
  };
  const onTestUserIdChg = (e) => {
    setTestUserId(e.target.value);
  };
  const handlePressEnter = (e) => {
    e.target.blur();
    onOk();
  };
  const content = (
    <div>
      <Form form={passwordForm}
        name="dashboard-pasword-form"
        className="filter-form"
        layout="vertical"
        onFinish={onOk}
        autocomplete="off"
        >
      <Form.Item style={{marginBottom:'12px' }}><Input allowClear value={testUserId} onChange={onTestUserIdChg} placeholder="Network Id"/></Form.Item>
      <Form.Item name="password" style={{marginBottom:'12px' }}><Input.Password allowClear placeholder="Password"/></Form.Item>
      <div className="btn-footer flex justify-end" style={{marginTop:"8px"}}>
        {/* <Button onClick={onClear} className="default-btn">
          Clear
        </Button> */}
        <Button htmlType="submit" type="primary" className='primary-btn' style={{marginLeft:"4px"}}>
          Ok
        </Button>
      </div>
      </Form>
    </div>
  );
  const getTerritoryData = (userId) => {
    fetchTerritoryData(userId).then(territoryData => {
      if(territoryData.role !== 'Corp' && territoryData.market === null && territoryData?.role !== 'RCM'){ //if incorrect users (1,tied to a territory and this territory is assigned to the sites which are not supported by SIA+;2, not tied to sites but role is site) , will disable options and show message
        message.error(`No data to display for user - ${testUserId}`,10);
        setOrgDropdownList({"market":[],"region":[],"site":[]});
        setTerritoryList([]);
        setDistrictList([]);
        const initOrgSelectedList ={"market":[],"region":[],"site":[],"territory":[]};
        setOrgSelectedList(initOrgSelectedList);
        saveInitTerritoryFiltersData(initOrgSelectedList);
        form.setFieldsValue(initOrgSelectedList);
      }else{
        const role = territoryData.role;
        const sellerId = territoryData.sellerId;
        const defaultMarket = territoryData.market ? territoryData.market.split() : null;
        const defaultRegion = territoryData.regions ? territoryData.regions : null;
        const defaultSiteObj = territoryData.sites;
        const defaultSite = territoryData.sites ? territoryData.sites.map(item => item.site_nbr) : null;
        const defaultOrgSelectedList = {
          market: defaultMarket,
          region: defaultRegion,
          site: defaultSite,
        };

        fetchOrgInfo(defaultMarket, defaultRegion, defaultSiteObj,role,sellerId).then(orgData => {
          const initOrgList = orgData.orgList;
          const defaultOrgDropdownList = orgData.orgConstData;
          let initOrgSelectedList = JSON.parse(JSON.stringify(defaultOrgDropdownList));
          initOrgSelectedList.site = initOrgSelectedList.site.map(item => item.site_nbr);
          setOrgDropdownList(defaultOrgDropdownList);
          let defaultFormValues;
          if(role === 'Market') {
            defaultFormValues = defaultMarket;
          }else if(role === 'Region') {
            defaultFormValues = defaultRegion;
          }else if(role === 'Site' || role === 'MA') {
            //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
            defaultFormValues = defaultSite.filter((item1) => defaultOrgDropdownList.site.some((item2) => item2.site_nbr === item1));
          }else {
            defaultFormValues = [];
          }
          //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
          const territories = uniqueTerritoryList(getTerritoryList(initOrgSelectedList.site,territoryData.territories));
          // setTerritoryList((territories && territories.filter(item => item.type === 'TERRITORY')) || []);
          const defaultDistrict = (territories && territories.filter(item => item.type === 'DISTRICT')) || [];
          setDistrictList(defaultDistrict);
          if(defaultDistrict.length !== 0 && defaultSite && defaultSite.length !== 0){
            fetchTerritoryByDistrict(defaultSite.join(', '),defaultDistrict.map(item => item.terrName).join(', ')).then(data=>{
              if(data.length !== 0){
                setTerritoryList(uniqueTerritoryList(data));
                // setTerritoryByDistrictList(uniqueTerritoryList(data));
              }else{
                const territories = territoryData ? uniqueTerritoryList(getTerritoryList(orgSelectedList.site, territoryData.territories)) : null;
                setTerritoryList((territories && territories.filter(item => item.type === 'TERRITORY')) || []);
              }
            });
          }
          const newOrgList = getOrgSelectedList(
            defaultFormValues,
            role,
            defaultOrgSelectedList,
            initOrgList,
            defaultOrgDropdownList,
            setOrgDropdownList,
            territories,
            setTerritoryList,
            territories,
            conceptIdDropdown,
            territoryData,
            setDistrictList,
          );
          initOrgSelectedList = newOrgList.newOrgSelectedList;
          setOrgSelectedList(initOrgSelectedList);
          saveInitTerritoryFiltersData(initOrgSelectedList);
          //initial form default Values: Region Role, Site Role, MA Role
          form.setFieldsValue(initOrgSelectedList);
        });
      }
      fetchNationalAccountData().then(data=>{
        let dom = document.getElementById("searchButton"); //Initial loading data in dashboard page,need Execute at the end of all APIs, otherwise the field's error message will be refreshed. //COT-8438: SIA - Initial loading page in dashboard page 
        if(dom){
          dom.click();
        }
      });
    });
  }
  useEffect(() => {
    if (orgList.length === 0) {
      const userId = auth.user.userId;
      getTerritoryData(userId)    
    }
  }, []); // eslint-disable-line
  useEffect(() => {
    let territories = [];
    if(dashboardConditionData) {
      form.setFieldsValue(dashboardConditionData);
      setOrgSelectedList(dashboardConditionData);
      if(!_.isEmpty(dropDownList)){
        setOrgDropdownList(dropDownList);
      }
      //COT-9468:SIA+ - INC000005313896 - UI - Add a district filter in SIA+ dashboard. if switch menu need set territory list
      territories = territoryData ? uniqueTerritoryList(getTerritoryList(dashboardConditionData.site,territoryData.territories)) : null;
    } else {
      form.setFieldsValue(initTerritoryFiltersData);
      setOrgSelectedList(initTerritoryFiltersData || orgSelectedList);
      //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
      territories = territoryData ? uniqueTerritoryList(getTerritoryList(orgSelectedList.site,territoryData.territories)) : null;
    }
    //COT-9468:SIA+ - INC000005313896 - UI - Add a district filter in SIA+ dashboard. if switch menu need set territory list
    if(territoryByDistrictData.length !== 0){
      setTerritoryList(uniqueTerritoryList(territoryByDistrictData));
    }else{
      setTerritoryList(uniqueTerritoryList(territories && territories.filter(item => item.type === 'TERRITORY')) || []);
    }
    setDistrictList((territories && territories.filter(item => item.type === 'DISTRICT')) || []);
  }, [dashboardConditionData]); // eslint-disable-line

  //COT-8438: SIA - Initial loading page in dashboard page
  //if not select any filters in dashboard page, and switch to other page in other menu,then rollback to dashboard page,need auto click on search button
  useEffect(()=>{
    if(!dashboardConditionData){
      let dom = document.getElementById("searchButton");
      if(dom){
        dom.click();
      }
    }
  },[dashboardConditionData]);// eslint-disable-line
  const onFinish = values => {
    let allTerritories = territoryData;
    if(territoryData?.territories){
      allTerritories = {...territoryData,territories: getDistinctObjects(territoryData?.territories.filter(item=>item.type === 'TERRITORY').concat(territoryByDistrictData),'terrName','opcoId')};
    }
    const submitData = {
      deliveryDate: moment(values.deliveryDate).format(DASH_DATE_FORMAT),
      sites: values.site,
      territoryInfoList: formatTerrirotyData(values, allTerritories),
      conceptIds: values.conceptId !== undefined ? values.conceptId : [],
      nationalIds: values.nationalId !== undefined
      ? Array.isArray(values.nationalId)
        ? values.nationalId : typeof values.nationalId === 'string' ? values.nationalId.split() : [] : [],
      accountTypes: values.accountType !== undefined ? values.accountType : [],
    };
    const timing = Date.now();
    fetchSummaryData(submitData);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch dashboard data`,timing);
    });
    saveDashboardConditionData({...values, district:values?.district});//COT-9468:SIA+ - INC000005313896 - UI - Add a district filter in SIA+ dashboard.if change menu will save district field
    //if switch menu will save drop down list for market , region and site fields
    saveDropDownList(orgDropdownList);
  };

  const handleSelectChange = (value, label) => {
    const newList = getOrgSelectedList(
      value,
      label,
      orgSelectedList,
      orgList,
      orgDropdownList,
      setOrgDropdownList,
      territoryData.territories,
      setTerritoryList,
      territoryList,
      conceptIdDropdown,
      territoryData,
      setDistrictList,
    );
    if(label === 'accountType' && _.isEmpty(value)){
      newList.newOrgSelectedList.accountType = ['CMU', 'LCC','TRS','TRP','OTH'];
    }
    setOrgSelectedList(newList.newOrgSelectedList);
    form.setFieldsValue(newList.newOrgSelectedList);
  };
  // const [territoryByDistrictList, setTerritoryByDistrictList] = useState([]);
  const handleDistrictChange = (value, label) => {
    //call fetch territory by district api,then call handleSelectChange function 
    let selectedList = value;
    const lastedSelectOption = selectedList[selectedList.length - 1];
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = districtList.map(item => item.terrName);
    }
    setOrgSelectedList({
      ...orgSelectedList,
      territory: [],
      [label]: selectedList,
    });
    form.setFieldsValue({
      ...orgSelectedList,
      territory: [],
      [label]: selectedList,
    });
    //call api to set territory list based on site and district
    // setTerritoryList(uniqueTerritoryList(territoryByDistrict));
    const { site } = form.getFieldsValue();
    if(value && value.length !== 0 && selectedList.length !== 0){
      fetchTerritoryByDistrict(site.join(', '),selectedList.join(', ')).then(data=>{
        //if not find any territory,will set territory list as init territory list
        if(data.length !== 0){
          setTerritoryList(uniqueTerritoryList(data));
          // setTerritoryByDistrictList(uniqueTerritoryList(data));
        }else{
          const territories = territoryData ? uniqueTerritoryList(getTerritoryList(orgSelectedList.site, territoryData.territories)) : null;
          setTerritoryList((territories && territories.filter(item => item.type === 'TERRITORY')) || []);
          // setTerritoryByDistrictList([]);
          resetTerritoryByDistrictData();
        }
      })
    }else{
      const territories = territoryData ? uniqueTerritoryList(getTerritoryList(orgSelectedList.site, territoryData.territories)) : null;
      setTerritoryList((territories && territories.filter(item => item.type === 'TERRITORY')) || []);
      // setTerritoryByDistrictList([]);
      resetTerritoryByDistrictData();
    }
  }
  const getConceptIdsList = value => {
    //except onClear
    form.setFieldsValue({ conceptId: undefined });
    if (value !== undefined) {
      fetchConceptIdsData(value);
    }
  };

  const disabledDeliveryDate = currentDate => {
    return currentDate && currentDate < moment().subtract(3, 'days');
  };

  const onReset = () => {
    form.resetFields();
    //if click reset, need fill in the default value for nationalId and conceptId
    if (territoryData?.role === 'RCM' && !_.isEmpty(territoryData?.nationalIds)) {
      form.setFieldsValue({ nationalId: territoryData?.nationalIds });
      // fetchConceptIdsData(territoryData?.nationalIds);
      setConceptIdDropdown(territoryData?.conceptIds || []);
    }else{
      setConceptIdDropdown([]);
    }
    resetSummaryData();
    resetReportsData();
    resetDashboardConditionData();
    resetTerritoryByDistrictData();
  };
  return (
    <div className="dashboard-territory-filters">
      <Form
        form={form}
        name="dashboard-filter-form"
        className="filter-form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          deliveryDate: moment().add(1, 'days'),
          accountType:['CMU', 'LCC', 'TRS', 'TRP', 'OTH'],
        }}
      >
        <div className='is-show-pc'>
          <Row className="font-size-16 sub-title">
            <Col>
              <Popover
                content={content}
                title=""
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
                placement="right"
              >
               {t('filters')}
              </Popover>
            </Col>
          </Row>
        </div>
        {/* disabled clickable on filter for mobile screen */}
        <div className='is-show-mobile'>
          <Row className="font-size-16 sub-title">
            {t("filters")}
          </Row>
        </div>
        <div className="dashboard-filter-item is-show-pc">
          <div className="dashboard-filter-head">
            <h4>{t('filters')}</h4>
            <FontAwesomeIcon icon={faSliders} className="filter-but-right-mobile is-show-mobile" onClick={isClosePopFun}/>
          </div>
          <Form.Item name="market" 
            // label="Market"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              onChange={value => handleSelectChange(value, 'market')}
              disabled={
                territoryData && territoryData.role && territoryData.role.toLowerCase() !== 'corp' && territoryData?.role !=='RCM'
              }
              placeholder={t("market")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t('allMarkets')}
              </Select.Option>
              {orgDropdownList.market.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="region" 
          // label="Region"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'region')}
              disabled={
                orgSelectedList.market.length === 0 ||
                (territoryData &&
                  territoryData.role &&
                  territoryData.role.toLowerCase() !== 'corp' &&
                  territoryData.role.toLowerCase() !== 'market' && 
                  territoryData?.role.toLowerCase() !== 'ma'
                  && territoryData?.role !=='RCM')
              }
              placeholder={t("region")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t('allRegions')}
              </Select.Option>
              {orgDropdownList.region.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="site"
            // label="Site"
            rules={[{ required: true, message: t('pleaseSelectSite') }]}
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'site')}
              disabled={
                orgSelectedList.region.length === 0 
                //COT-8439: SIA - Enable site select when user role is Site
                // ||(territoryData && territoryData.role && territoryData.role.toLowerCase() === 'site')
              }
              placeholder={t("site")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t("allSites")}
              </Select.Option>
              {orgDropdownList.site.map(item => {
                const siteNbr = item.site_nbr;
                const siteNbrName = item.site_desc;
                return (
                  <Select.Option key={siteNbr} value={siteNbr}>
                    {`${siteNbr} - ${siteNbrName}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="accountType"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'accountType')}
              placeholder={t('dashboard_accountType')}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                {t('all_account_types')}
              </Select.Option>
              {ACCOUNT_TYPES.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="district"
            // rules={[
            //   {
            //     required: territoryData && territoryData.role.toLowerCase() === 'ma',
            //     message: t(ERROR_SELECT_TERRITORY),
            //   },
            // ]}
          >
            <Select
              mode='multiple'
              allowClear
              maxTagCount={1}
              maxTagTextLength={10}
              onChange={(value, option) => handleDistrictChange(value, 'district')}
              disabled={orgSelectedList.site.length === 0 || territoryList.length === 0 }
              placeholder={t("dashboard_district")}
            >
              {districtList ? (
                <>
                  <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allDistricts')}
                  </Select.Option>
                  {districtList.map(item => {
                    return (
                      <Select.Option key={item.terrId} value={item.terrName}>
                        {item.terrName}
                      </Select.Option>
                    );
                  })}
                </>
              ) : null}
            </Select>
          </Form.Item>
          <Form.Item
            name="territory"
            // label="Territory"
            rules={[
              {
                required: territoryData && territoryData.role.toLowerCase() === 'ma',
                message: t(ERROR_SELECT_TERRITORY),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={10}
              onChange={(value, option) => handleSelectChange(value, 'territory')}
              disabled={orgSelectedList.site.length === 0 || territoryList.length === 0}
              placeholder={t("territory")}
            >
              {territoryList ? (
                <>
                  <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allTerritories')}
                  </Select.Option>
                  {territoryList.map(item => {
                    return (
                      <Select.Option key={item.terrId} value={item.terrName}>
                        {item.terrName}
                      </Select.Option>
                    );
                  })}
                </>
              ) : null}
            </Select>
          </Form.Item>
          <Form.Item name="nationalId" 
          // label="Natl ID"
          >
            <Select
              mode={isRcmUser ? "multiple" : ""}
              showSearch
              allowClear
              onChange={value => getConceptIdsList(value)}
              onClear={() => {
                resetConceptIdsData();
              }}
              placeholder={t("natlId")}
              disabled={isCanadaCorpUser || isRcmUser}
            >
              {nationalData &&
                nationalData.map(item => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="conceptId" 
          // label="Concept ID"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={6}
              onChange={(value, option) => handleSelectChange(value, 'conceptId')}
              placeholder={t("conceptId")}
              disabled={isCanadaCorpUser}
            >
              {conceptIdDropdown && (
                <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allConceptIds')}
                </Select.Option>
              )}
              {conceptIdDropdown &&
                conceptIdDropdown.map(item => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="deliveryDate"
            // label="Delivery Date"
            rules={[{ required: true, message: t('plsSelectDeliveryDate') }]}
          >
            <DatePicker disabledDate={disabledDeliveryDate} placeholder="Delivery Date"/>
          </Form.Item>
          <Form.Item style={{textAlign:'center' }}>
            <Button htmlType="button" onClick={onReset} className="default-btn filter-btn" style={{marginRight:'10px' }}>
              {t('reset')}
            </Button>
            <Button id ="searchButton" htmlType="submit" type="primary" className="primary-btn filter-primary-btn"  onClick={isClosePopFun}>
            {t('search')}
            </Button>
          </Form.Item>
        </div>
        <div className={isOpenPop ? "dashboard-filter-item is-show-mobile":"dashboard-filter-item none"}>
          <div className="dashboard-filter-head">
            <h4>{t("filters")}</h4>
            <FontAwesomeIcon icon={faSliders} className="filter-but-right-mobile is-show-mobile" onClick={isClosePopFun}/>
          </div>
          <Form.Item name="market" 
            // label="Market"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              onChange={value => handleSelectChange(value, 'market')}
              disabled={
                territoryData && territoryData.role && territoryData.role.toLowerCase() !== 'corp' 
                && territoryData?.role !== 'RCM'
              }
              placeholder={t("market")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t('allMarkets')}
              </Select.Option>
              {orgDropdownList.market.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="region" 
          // label="Region"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'region')}
              disabled={
                orgSelectedList.market.length === 0 ||
                (territoryData &&
                  territoryData.role &&
                  territoryData.role.toLowerCase() !== 'corp' && territoryData?.role !=='RCM' &&
                  territoryData.role.toLowerCase() !== 'market' && 
                  territoryData?.role.toLowerCase() !== 'ma')
              }
              placeholder={t("region")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t('allRegions')}
              </Select.Option>
              {orgDropdownList.region.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="site"
            // label="Site"
            rules={[{ required: true, message: t('pleaseSelectSite') }]}
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'site')}
              disabled={
                orgSelectedList.region.length === 0 
                // ||(territoryData && territoryData.role && territoryData.role.toLowerCase() === 'site')
              }
              placeholder={t("site")}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
              {t("allSites")}
              </Select.Option>
              {orgDropdownList.site.map(item => {
                const siteNbr = item.site_nbr;
                const siteNbrName = item.site_desc;
                return (
                  <Select.Option key={siteNbr} value={siteNbr}>
                    {`${siteNbr} - ${siteNbrName}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="accountType"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={9}
              onChange={value => handleSelectChange(value, 'accountType')}
              placeholder={t('dashboard_accountType')}
            >
              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('all_account_types')}
              </Select.Option>
              {ACCOUNT_TYPES.map(item => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="district"
          >
            <Select
              mode='multiple'
              allowClear
              maxTagCount={1}
              maxTagTextLength={10}
              onChange={(value, option) => handleDistrictChange(value, 'district')}
              disabled={orgSelectedList.site.length === 0 || territoryList.length === 0 }
              placeholder={t("dashboard_district")}
            >
              {districtList ? (
                <>
                  <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allDistricts')}
                  </Select.Option>
                  {districtList.map(item => {
                    return (
                      <Select.Option key={item.terrId} value={item.terrName}>
                        {item.terrName}
                      </Select.Option>
                    );
                  })}
                </>
              ) : null}
            </Select>
          </Form.Item>
          <Form.Item
            name="territory"
            // label="Territory"
            rules={[
              {
                required: territoryData && territoryData.role.toLowerCase() === 'ma',
                message: t(ERROR_SELECT_TERRITORY),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={10}
              onChange={(value, option) => handleSelectChange(value, 'territory')}
              disabled={orgSelectedList.site.length === 0 || territoryList.length === 0}
              placeholder={t("territory")}
            >
              {territoryList ? (
                <>
                  <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allTerritories')}
                  </Select.Option>
                  {territoryList.map(item => {
                    return (
                      <Select.Option key={item.terrId} value={item.terrName}>
                        {item.terrName}
                      </Select.Option>
                    );
                  })}
                </>
              ) : null}
            </Select>
          </Form.Item>
          <Form.Item name="nationalId" 
          // label="Natl ID"
          >
            <Select
              mode={isRcmUser ? "multiple" : ""}
              showSearch
              allowClear
              onChange={value => getConceptIdsList(value)}
              onClear={() => {
                resetConceptIdsData();
              }}
              placeholder={t("natlId")}
              disabled={isCanadaCorpUser || isRcmUser}
            >
              {nationalData &&
                nationalData.map(item => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="conceptId" 
          // label="Concept ID"
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={6}
              onChange={(value, option) => handleSelectChange(value, 'conceptId')}
              placeholder={t("conceptId")}
              disabled={isCanadaCorpUser}
            >
              {conceptIdDropdown && (
                <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                  {t('allConceptIds')}
                </Select.Option>
              )}
              {conceptIdDropdown &&
                conceptIdDropdown.map(item => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="deliveryDate"
            // label="Delivery Date"
            rules={[{ required: true, message: t('plsSelectDeliveryDate') }]}
          >
            <DatePicker disabledDate={disabledDeliveryDate} placeholder="Delivery Date"/>
          </Form.Item>
          <Form.Item style={{textAlign:'end' }}>
            <Button htmlType="button" onClick={onReset} className="default-btn filter-btn" style={{marginRight:'10px' }}>
            {t('reset')}
            </Button>
            <Button htmlType="submit" type="primary" className="primary-btn filter-primary-btn" data-dd-action-name="Search Button" onClick={isClosePopFun}>
            {t('search')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

TerritoryFilters.propTypes = {};
TerritoryFilters.defaultProps = {};
